* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.INR6 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 96.7%;
  /* or 23px */
  position: relative;
  left: -2.5%;
  letter-spacing: -0.03em;

  color: #7D7EC7;
  margin-left: 2px;
  top: -40px;

}

.starting {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 96.7%;
  /* identical to box height, or 15px */
  position: relative;
  top: -40px;
  letter-spacing: -0.03em;

  color: #626262;


}

.service-view-all {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 169.9%;
  /* identical to box height, or 54px */

  letter-spacing: 0.02em;
}

.font-service2 {

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 169.9%;
  /* identical to box height, or 54px */

  letter-spacing: 0.02em;

  color: #5E5E5E;
}

.carousel1 {
  background: white;
  height: 380px;
  padding-top: px;
  display: block;
  width: 90%;
  padding-bottom: px;
  position: relative;
  top: px;
  box-sizing: border-box;

  box-shadow: 0px 72.5294px 108.794px rgba(0, 0, 0, 0.07);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  justify-content: center;
}

.service-illus {
  position: relative;
  top: -15px;
}

.service-content {
  position: relative;
  top: -18px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 169%;
  width: 95%;
  height: 100px;
  /* or 26px */


  text-align: center;
  letter-spacing: 0.02em;

  margin: auto;


  color: #000000;
}

.service-content4 {
  position: relative;
  top: -30px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 169%;
  width: 300px;
  height: 80px;
  /* or 26px */


  text-align: center;
  letter-spacing: 0.02em;

  margin: auto;


  color: #000000;
}

.service-content2 {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 169%;
  width: 88%;
  /* or 26px */
  position: relative;
  top: -15PX;
  text-align: center;
  letter-spacing: 0.02em;
  height: 120px;
  height: 160PX;

  color: #000000;
}

.carousel-cell1 {

  display: block;
  width: 310px;
  height: 300px;
  margin-left: 50px;
  margin-right: 30px;
  margin-top: 50px;
  margin-bottom: 70px;

  counter-increment: carousel-cell;

  background: white;
  box-shadow: 0px 3.93247px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;


}

/* all services cards */
.carousel-cell10 {

  display: block;
  width: 310px;
  height: 310px;



  margin: 20px auto;


  counter-increment: carousel-cell;
  position: relative;
  top: -50px;
  background: white;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);
  border-radius: 10px;


}

.slider-profilepic {
  background-color: white;
  width: 40px;
  height: 40px;
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 10px;
}

/* .carousel-cell.is-selected {
      background: #ED2;
    } */

/* cell number */
/* .carousel-cell:before {
      display: block;
      text-align: center;
      content: counter(carousel-cell);
      line-height: 200px;
      font-size: 80px;
      color: white;
      
    }  */
.cells {
  width: 400px;
  height: 800px;

}

.slider {
  width: 90%;
  position: relative;
  left: 9%;
  top: -100px;


}

/* .slider2{
      position: relative;
      top: 140px;
      
      width:100%;
      height: 250px;
    
    
    } */
/* .flickity-prev-next-button{
      top: 10px;
      -webkit-transform: none;
      transform: none;
    } 
    
    .flickity-prev-next-button.previous{
      left: auto;
      right:60px;
      background: none;
    }
    
    .flickity-prev-next-button.next{
      
      right:10px;
    }
    /* */



.white {
  width: 40%;

  position: absolute;
  top: -120px;
  height: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 10px;
  border-bottom: 120px solid white;
  border-left: 0px solid transparent;
  border-right: 130px solid transparent;

  text-align: justify;
  padding-left: 40px;



}

.service-slider-head {

  top: 20px;
  left: -10%;
  margin-top: 20px;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 70.9%;
  /* identical to box height, or 54px */

  letter-spacing: 0.02em;

  color: #5E5E5E;
}

.seller-profile-name {
  background-color: #EEE;
  color: black;
  padding: 5px 20px 5px 20px;
  width: 250px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 80px;
  top: -5px;
}

.seller-profile-countries {
  background-color: #EEE;
  color: black;
  padding: 5px 20px 5px 20px;
  width: 250px;
  position: absolute;
  left: 80px;
  top: 40px;

}

.seller-profile-Degrees {
  background-color: #EEE;
  color: black;
  padding: 5px 20px 5px 20px;
  width: 250px;
  position: absolute;
  left: 80px;
  top: 90px;

}

/* .service-card2{
  width: 25%;
} */
.seller-price {
  position: absolute;
  top: 200px;
  width: 200px;
  left: 50px;
  color: #EEE;
  font-size: 20px;
}

.servicess-pic {
  background-color: #EEE;
  width: 70%;
  height: 100%;
  border-radius: 50%;
  padding: 20px;
  position: absolute;
  top: px;
  left: 20px;
}

@media (max-width:1280px) {

  .carousel-cell1 {

    margin-left: 10px;
  }

  .carousel1 {
    width: 100%;
  }

  .service-slider-head {
    font-size: 35px;
    left: -7%;
  }

}

@media (max-width:1026px) {
  .carousel-cell1 {

    margin-left: 10px;
    margin-right: px;

  }

  .carousel-cell10 {

    margin: auto;
    margin-top: 10px;
  }

  .white {
    width: 50%;
  }

}

@media (max-width:990px) {
  .carousel-cell1 {

    margin-left: 10px;
  }

  .white {
    width: 60%;
  }


  .slider {
    top: 10px;
  }

}

@media (max-width:830px) {


  .white {
    width: 90%;
    top: -120px;
  }

  .carousel1 {
    width: 100%;


  }

  .slider {
    width: 100%;
    left: -5%;
  }

  .carousel-cell10 {
    left: 0px;
    margin-top: 20px;
  }

}



@media (max-width:725px) {
  .carousel-cell1 {

    margin-left: 10px;
  }

  .service-view-all {
    font-size: 20px;
    margin-top: 20px;
  }

  .carousel1 {
    width: 100%;
    margin: auto;
    background-color: white;
    position: relative;
    top: -117px;
 
  }



  .white {
    width: 100%;
    height: 130px;
    margin: auto;

    position: relative;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 10px;
    border-bottom: 0px solid white;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    padding-left: 10px;
    padding-top: 10px;
    text-align: center;



  }




  .slider {
    width: 100%;
    margin: auto;
    position: relative;
    padding-left: 37px;

  }

  .service-slider-head {
    left: -9%;
    font-size: 30px;
  }

}


@media (max-width:540px) {
  .carousel-cell1 {

    margin-left: 20px;
  }

  .carousel1 {
    width: 100%;
    height: 380px;

  }

  .slider {
    top: 60px;
    width: 100%;
  }


  .white {
    top: -120px;
    width: 100%;
  }

  .service-slider-head {

    font-size: 25px;
  }

  .font-service {
    left: 3.2%;
    font-size: 15px;
  }
}

@media (max-width:394px) {


  .carousel-cell10 {

    height: 320px;
  }


  .service-slider-head {
    font-size: 24px;
    left: -5%;
  }

  .font-service {
    font-size: px;
    left: 5.9%;

  }

  
}




@media (max-width:380px) {
  .service-slider-head {
    font-size: 24px;
    left: 8%;
  }

  .font-service {
    font-size: 12px;
    left: 10%;
  }
}

@media (max-width:350px) {
  .service-slider-head {
    font-size: 20px;
    left: 7%;
  }

  .white {
    height: 150px;
  }



}