.new-header {
    width: 100%;
    position: fixed;
    top: 0;
    padding: 20px;
    justify-content: space-between;
    height: 90px;
    display: flex;

    z-index: 9999;
    background: transparent;
}

.otpmain {
    width: 100%;
    position: absolute;
    z-index: 98;

}

.size {
    height: 100px;
}

.otp-head {
    font-family: 'Baloo Thambi 2', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30.1568px;

    /* identical to box height */
    color: #7075C4;
    margin: 20px auto;
}

.loggedout {
    background: transparent;
    border: none;
}

.otp-head2 {
    font-family: 'Baloo Thambi 2', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20.1568px;

    /* identical to box height */


    color: #303035;
    margin: 20px auto;
}

.otp-button {

    border: none;
    background-color: #7075C4;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16.1841px;
    color: white;
    /* identical to box height */

    width: 100%;
    margin-top: 10px;

    border-radius: 5.09207px;
    padding: 5px 10px 5px 10px;
}

.otp {
    height: auto;
    background-color: white;
    width: 40%;
    margin: auto;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    margin-top: 100px;

}

.otp-width {
    width: 100%;
}

.otp-second {
    width: 90%;
    margin: auto;

}

.otp-margin {
    margin-bottom: 20px;
}

.linkDecor {
    text-decoration: none;
    color: #7D7EC7;
}

.brand-name {
    text-decoration: none;
    color: #000000;
    position: relative;
}

.header-head {
    font-family: 'Baloo Thambi 2', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30.1568px;
    line-height: 77px;
    /* identical to box height */


    color: #303035;
    margin-top: 2px;

}

.ul-head {
    display: flex;
}

.ul-drop {
    position: absolute;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 0px 0px 0.99px rgba(0, 0, 0, 0.533);

}

.ul-drop5 {
    position: absolute;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 0px 0px 0.99px rgba(0, 0, 0, 0.533);
    top: 75px;
}

.ul-drop2 {
    position: absolute;
    background-color: white;
    width: 10%;
    padding: 5px;
    border-radius: 10px;
    z-index: 99;
    box-shadow: 0px 0px 0.99px rgba(0, 0, 0, 0.533);

}

.service-link2 {
    text-decoration: none;
    color: #7D7EC7;
}

.li-head {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 17.0118px;
    line-height: 35px;

    color: #000000;

    cursor: pointer;
    list-style: none;
    margin: 20px;
    margin-top: 40px;
}

.li-head2 {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 17.0118px;
    line-height: 35px;

    color: #000000;

    margin-top: 10px;
    list-style: none;

}

.get-started {
    border-radius: 90px;
    padding: 5px 20px 5px 20px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 600;
    font-size: 15.8468px;
    line-height: 28px;
    /* identical to box height */
    border: none;
    background-color: #7176C4;
    color: white;
}

.get-started:hover {
    background-color: #7177c4d3;
}

.loginn {
    color: #7075C4;
}

.get-started2 {
    border: none;
    background: transparent;
}

.ul-drop-view {
    position: absolute;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    z-index: 99;
    left: 90%;
    top: 80px;
    box-shadow: 0px 0px 0.99px rgba(0, 0, 0, 0.533);

}

.li-drop {
    list-style: none;
    font-family: 'Baloo Thambi 2', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20.5038px;
    line-height: 28px;
    /* identical to box height */
    padding: 5px;
    color: #7D7EC7;

}

.nav-item {
    margin-top: 10px;
}

.phone-nav {
    display: none;


}

.li-drop:hover {
    background: rgba(112, 117, 196, 0.49);
    cursor: grab;
}

@media (max-width:990px) {
    .pc-nav {
        display: none;
    }

    .ul-drop-view {
        position: relative;
        top: 0px;
        left: 0px;
        margin-top: 10px;
    }

    .otp {
        width: 60%;
    }

    .phone-nav {
        display: block;
        background-color: #F0EFF7;
        position: fixed;
        z-index: 99;
        width: 100%;
        color: #000000;



    }


}

@media (max-width:600px) {
    .otp {
        width: 90%;
    }

}