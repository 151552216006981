.chng {
    color: rgba(91, 92, 184, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-style: italic;
}

.heads {
    text-decoration: rgba(91, 92, 184, 1) solid underline;
    color: rgba(91, 92, 184, 1);
    font-family: 'Inter', sans-serif;
    margin-bottom: 25px;
}

.cookie {
    background: linear-gradient(180deg, #E6E5FF 0%, rgba(244, 244, 255, 0) 100%);
    text-transform: uppercase;
    color: rgb(91, 92, 184);
    letter-spacing: 2.5px;
    text-align: center;
    padding: 100px 100px 30px 100px;
    font-family: 'Gothic A1', sans-serif;
    font-size: 60px;
    margin-bottom: 10px;
}

.innertext {
    font-family: 'Gothic A1', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

}

.outline {
    margin: 90px;
}

hr {
    border: none;
    border-top: 1px solid rgb(57, 55, 55);
    width: 60%;
}

body {
    margin: 0;
}
@media (min-width: 280px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 16px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid rgb(57, 55, 55);
        width: 80%;
    }

}
@media (min-width: 320px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 16px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid rgb(57, 55, 55);
        width: 80%;
    }

}

@media (min-width: 375px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 26px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid rgb(57, 55, 55);
        width: 80%;
    }

}

@media (min-width: 425px) {
    .cookie {
        font-size: 30px;
        padding: 50px 0px 0px 0px;
        margin: 0;
        letter-spacing: 2px;
    }

    .outline {
        margin: 38px;
    }

    hr {
        border: none;
        border-top: 1px solid rgb(57, 55, 55);
        width: 80%;
    }

    .innertext {
        font-size: 12px;
    }

    .chng {
        font-size: 12px;
    }

    .heads {
        font-size: 14px;
        margin-bottom: 15px;
    }
}

@media (min-width: 768px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 70px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 16px;
    }

    .chng {
        font-weight: 700;
        font-size: 16px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 18px;
    }
}

@media (min-width: 1024px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 100px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 19px;
    }

    .chng {
        font-weight: 700;
        font-size: 19px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 22px;
    }
}

@media (min-width: 1440px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 120px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 20px;
    }

    .chng {
        font-weight: 700;
        font-size: 20px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 25px;
    }
}