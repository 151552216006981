.blog-banner {
    background-image: url("../images/blog.webp");
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.blog-content {
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    box-sizing: border-box;
  
}

.card {
    width: 30%;
    margin: auto;
    padding: 30px;
    background: rgba(240, 239, 247, 0.45);
}

.blog-heading {
    position: absolute;
    top: 90px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    margin-left: 50px;
    font-weight: bolder;
}

.view-blog-heading {
    position: absolute;
    left: 0%;
    top: 140px;
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    margin-left: 50px;
    font-weight: bolder;
}

.related-view-blog-heading {
    position: absolute;
    left: 59%;

    font-size: 18px;
    font-family: 'Inter', sans-serif;
    margin-left: 50px;
    font-weight: bolder;
}

.blog-data {
    display: flex;
    margin: 10px 10px 10px 10px;
    width: 100%;
}

.blog-data1 {
    width: 20%;
    height: 200px;
    background-color: darkgray;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: relative;
    top: 100px;
    left: -15%;
    margin: 10px 10px 10px 10px;
}

.blog-data2 {
    width: 20%;
    height: 200px;
    background-color: darkgray;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: relative;
    top: 100px;
    left: -15%;
    margin: 10px 10px 10px 10px;
}

.blog-data3 {
    width: 20%;
    height: 200px;
    background-color: darkgray;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: relative;
    top: 100px;
    left: -15%;
    margin: 10px 10px 10px 10px;
}

@media (max-width:600px) {
    .blog-data {
        display: block;
    }

    .blog-data1,
    .blog-data2,
    .blog-data3 {
        position: relative;
        left: 1%;
        width: 90%;
    }

    .blog-heading {
        margin: 27px;
    }

}

.blog-related-data-div {
    flex-basis: 30%;
    height: 1500px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
    background: #F4F3FF;

    display: block;

}

.blog-flex {
    display: flex;
}

.blog-inside-view {

    border-radius: 5px;
    width: 95%;





    padding: 90px;
    text-align: justify;


}

.blog-related-data {
    width: 80%;


    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    background-color: gray;

    margin: 10px 10px 10px 10px;
}

@media (max-width:990px) {
    .blog-content {
        display: block;
        width: 100%;

    }

    .blog-flex {

        display: block;
    }

    .blog-inside-view {
        width: 100%;
        padding: 10px;
    }

    .card {
        margin: auto;
        width: 90%;
    }

}