.contact-header {
    width: 100%;
    height: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 5px;
}

.contact-form {
    text-align: left;

}

.form-control {
    margin-top: 10px;
    margin-bottom: 10px;
}

.contact-info {
    font-weight: bolder;
}

.contact-link {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.conatact-heading {
    color: white;
    font-size: 80px;
    font-family: 'Gotham Book ', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 92px;
    position: relative;
    top: 100px;

}

.contact-us-all-detail {
    width: 90%;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    background: #E9E9F5;
    position: relative;
    display: flex;
    border-radius: 10px;
    margin-top: px;
    margin-bottom: 70px;
}

.contact-content {
    position: relative;
    top: 100px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 158.02%;
    /* identical to box height, or 35px */

    text-align: center;
    letter-spacing: 0.02em;

    color: #FFFFFF;
}

.contact-flex1 {
    flex-basis: 30%;

}

.contact-flex3 {
    flex-basis: 30%;

}

.contact-flex2 {
    flex-basis: 30%;

}

.btn-box {
    display: flex;
    justify-content: center;
}

.btn {
    background-color:
        #6364B6;

    border: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 24px;

    color: #FFFFFF;
    margin-top: 48px;

}

.btn:hover {
    background-color: rgba(99, 100, 182, 1);
    color: white;
}

.form-label {
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;

    color: #000000;
}

.forms {
    width: 60%;
    background-color: white;
    box-shadow: 0px 4px 66px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 15px 40px 40px 40px;
    min-height: 600px;
    position: relative;
    top: -150px;


}

.pad-box {
    padding-top: 20px;
}

.contact-detail {
    text-align: center;
    justify-content: center;
    /* top: px; */
    width: 100%;
    height: 500px;
    position: relative;
    background-image: url("../../images/contact.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
}


.contact-detail::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.32);
}

.contact-3d {
    position: relative;
    top: 30px;
    left: 3%;
    width: 40%;
    height: 270px;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.contact-qurey {
    position: relative;
    top: 90px;
    width: 100%;
    height: 320px;
    background-color: #D9D9D9;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

@media (max-width:800px) {
    .contact-us-all-detail {
        display: block;

        padding: 10px;
        height: auto;
    }

    .conatact-heading {
        font-size: 60px;

    }

    .contact-form {
        width: 100%;
    }

    .forms {
        width: 70%;
    }

    .contact-flex1,
    .contact-flex2,
    .contact-flex3 {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}

@media (max-width:400px) {
    .conatact-heading {
        font-size: 40px;
    }

}

@media (max-width:320px) {
    .conatact-heading {
        font-size: 38px;
    }

    .forms {
        width: 90%;
    }

}