.user-banner {
  display: flex;
  position: relative;
  top: 130px;
  background: linear-gradient(90deg, #5759AB 0%, #B2B2EA 98.67%);
  margin: auto;
  width: 98%;
  height: 200px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  justify-content: space-between;
}

.personalCards-margin {
  margin-left: 90px;
  margin-right: 90px;
}

.service-link {
  text-decoration: none;
  color: #000000;
}

.seller-personal-viewAll {
  background: transparent;
  width: 200px;
  border: none;
  color: #7D7EC7;
  text-decoration: underline;
}

.serviceCompIcon {
  position: relative;
  top: 90px;
  left: -35%;
  display: none;
}

.servcie-comp-link {
  text-decoration: none;
  color: #5D66B5;

}

.service-pages-head {
  margin-bottom: 20px;
  font-family: 'Gotham Book', sans-serif;
  font-style: "normal";
  font-weight: 400;
  font-size: 45px;
  position: relative;
  top: 200px;

  line-height: 46px;
  color: #7D7EC7
}

.service-line {
  width: 50%;
  position: relative;
  top: 200px;
  margin: auto;

  background: #7D7EC7;
  height: 5px;
  border-radius: 5px
}

.banner1 {
  height: 200px;
  flex-basis: 40%;
  text-align: left;
  padding-left: 30px;
  padding-top: 20px;




}

/* explore-heading */
.explore-all-services {
  font-style: bolder;
  margin-bottom: 20px;
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: "400";
  font-size: 40px;
  position: relative;

  top: 200px;

  line-height: 46px
}

/* explore line */
.explore-line {
  width: 50%;
  position: relative;

  top: 200px;

  margin: auto;

  background: #7D7EC7;
  height: 5px;
  border-radius: 5px;
}

.slider6 {
  position: relative;
  top: -200px;
}

.slider5 {
  margin: 20px;
  left: 5%;

}

.banner-today {
  color: #F97269;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 400;
  top: 10px;
  font-size: 22px
}

.banner-top-c {

  color: white;

  font-family: 'Gotham Book', sans-serif;
  font-weight: 500;
  margin-top: 30px;
  font-size: 22px
}

/* service page heading color */
.service-color {
  color: white;
}

.banner-get {

  color: white;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 400;
  top: 10px;
  font-size: 20px
}

.abroad {

  color: #F97269;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 600;

  font-size: 30px
}

.banner-heading {

  top: 10px;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 600;
  color: white;
  font-size: 30px;
}

.banner-heading2 {

  top: 10px;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-weight: 600;
  color: white;
  font-size: 30px;
}

.banner2 {
  height: 200px;
  flex-basis: 300px;
  padding: auto;
  float: right;


}

.service-comp-mobile {
  display: none;
}

.user-illus {

  width: 75%;
}

.viewAllService {
  position: relative;
  top: 120px;
  background: #7D7EC7;
  border: none;
  color: white;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  margin-top: 13px;

  border-radius: 5px;
  padding: 3px 20px 3px 20px;
}

.cardName {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  position: relative;
  top: 90px;
  margin: auto;
  line-height: 158.02%;
  /* identical to box height, or 41px */

  letter-spacing: 0.02em;

  color: #000000;
}

.flag {
  background-color: white;
  padding: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  position: relative;



  font-size: 13px;

}


.user-landing-heading2 {
  margin-bottom: 20px;
  font-family: 'Gotham Book', sans-serif;
  top: -140px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  position: relative;
  line-height: 46px;
  /* identical to box height */
  color: #7D7EC7;
}

.user-heading1 {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 46px;
  /* identical to box height */
  position: relative;
  top: 180px;
  color: #7D7EC7;
}

.degree {
  position: relative;
  top: 15px;

  color: #626262;
}

.degree-type {
  top: 15px;
  position: relative;
}

.service-S-from {

  top: 25px;
  position: relative;
  font-size: 15px;
  color: #626262;
}

.service-head2 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  position: relative;
  top: -30px;
  color: #111111;

}

.service-head4 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  position: relative;
  top: -35px;
  color: #111111;

}

.INR {
  position: relative;
  left: -35%;
  top: px;
  margin: 2px;
  color: #7D7EC7;
}

.INR8 {
  margin-left: 15px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 96.7%;
  /* or 23px */
  position: relative;
  left: -2.5%;
  letter-spacing: -0.03em;
  position: relative;
  top: -40px;
  color: black;
}

.teda-meda {

  position: absolute;
  left: -10%;
  top: 340px;
  width: 38%
}

.Service-li {
  list-style: none;
  color: #5D66B5;
  margin: auto;


}

.service-card {
  width: 130px;
  height: 130px;
  background-color: #8D8FD1;
  position: relative;
  top: 90px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin: auto;

}








.carousel6 {
  background: linear-gradient(126.03deg, rgba(110, 115, 255, 0.41) 3.16%, rgba(151, 102, 255, 0.37) 78.4%);
  height: 450px;

  box-sizing: border-box;
  border-radius: 40px;
  margin-top: 300px;
}

.carousel8 {
  background: linear-gradient(126.03deg, rgba(110, 115, 255, 0.41) 3.16%, rgba(151, 102, 255, 0.37) 78.4%);
  border-radius: 49.6241px;
  height: 450px;
  padding-top: px;
  box-sizing: border-box;
  margin-top: 50px;

}

.carousel7 {
  background: linear-gradient(126.03deg, rgba(110, 115, 255, 0.41) 3.16%, rgba(151, 102, 255, 0.37) 78.4%);
  border-radius: 49.6241px;
  height: 420px;
  padding-top: px;
  box-sizing: border-box;

  margin-top: 50px;


}

.carousel-cell3 {


  padding: 20px;
  width: 20%;
  height: 430px;
  margin-left: 40px;
  background-color: white;
  box-shadow: 0px 24px 48px 3px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  position: relative;

  left: -px;
  text-align: center;
  justify-content: center;
  counter-increment: carousel-cell;
}

.INR9 {

  left: -36%;
}

.carousel3 {
  background: #F0EFF7;
  height: 500px;
  padding-top: px;
  box-sizing: border-box;
  margin-top: 400px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.carousel-cell18 {

  padding: 20px;
  width: 20%;
  height: 430px;
  flex-wrap: wrap;

  margin: 20px;
  left: 5%;



  background-color: white;
  box-shadow: 0px 0px 16.6488px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  position: relative;



}

.service-content {
  font-size: 205px;
}

.serviceCompIcon {
  display: none;
}

.Service-ul {
  display: flex;
  text-decoration: none;
  margin: auto;
  position: relative;
  top: 100PX;
}

.service-component {
  margin: auto;
}

.explore-top-counseller {
  position: relative;
  top: 200px;
  left: -0%;
}

.service-ul2 {
  position: relative;
  z-index: 99;
  width: 70%;
  margin: auto;
  background-color: white;
  top: 120px;
  padding: 20px;

}

.user-reco-content {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 150px;
  width: 14%;
  background-color: gray;
  position: relative;

  top: 280px;
  margin: 13px;
}

.user-explore-content {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 150px;
  width: 14%;
  background-color: gray;
  position: relative;

  top: 200px;
  margin: 13px;

}

.counseller-explore-heading {
  position: relative;
  top: 180px;
  left: -40%;
  font-weight: bold;


  padding: 10px;
  display: inline;

}

.counseller-reco-heading {
  position: relative;
  top: 290px;
  left: -40%;
  font-weight: bold;
  display: inline-block;
  padding: 10px;


}

.banner-boy {
  position: relative;
  top: -100px;

}


.banner-earth {
  position: relative;
  left: -28%;
  top: 40px;

}

.view-btn {
  position: relative;

  top: 10px;
  font-size: 12PX;
  color: white;
  border: none;
  width: 80%;
  font-weight: bolder;
  padding: 5PX;
  border-radius: 5px;
  background: linear-gradient(90deg, #7176C4 0.15%, #7D7EC7 99.78%);

}

.view-btn:hover {}

/* service slider */
.carousel4 {
  background: #F0EFF7;
  height: 430px;
  padding-top: 20px;
  position: relative;
  top: -170px;
  box-sizing: border-box;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.carousel17 {

  padding-top: 20px;

  box-sizing: border-box;
  position: relative;
  width: 100%;


}

.carousel-cell5 {
  margin: 20px auto;


  padding: 30px;
  padding-bottom: 20px;
  height: 330px;

  background: #FFFFFF;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);
  border-radius: 21.2581px;
  border-radius: 10px;

  position: relative;
  left: -px;

  margin-bottom: 20px;
  width: 350px;
  box-shadow: 0px 3.69973px 16.6488px rgba(0, 0, 0, 0.25);

  border-radius: px;
}

.carousel-cell4 {
  /* margin-left: 100px;
  margin-right: 30px; */

  margin-bottom: 20px;

  background-color: white;
  box-shadow: 0px 24px 48px -3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  left: -px;

  padding: 30px;

  height: 330px;


  background-color: white;
  background: #FFFFFF;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);

  border-radius: 10px;

  position: relative;
  left: -px;

  position: relative;
  left: 2%;
  width: 350px;
  box-shadow: 0px 3.69973px 16.6488px rgba(0, 0, 0, 0.25);

  border-radius: px;
  counter-increment: carousel-cell;
}

.carousel-cell15 {
  /* margin-left: 100px;
  margin-right: 30px; */

  width: 350px;
  margin: auto;

  background-color: white;
  box-shadow: 0px 24px 48px px rgba(0, 0, 0, 0.25);
  border-radius: 16px;



  height: 350px;


  background-color: white;
  background: #FFFFFF;
  box-shadow: 0px 0px 4.6961px rgba(0, 0, 0, 0.25);

  border-radius: 10px;





  background-color: white;
  box-shadow: 0px 24px 48px -3px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  position: relative;
  left: -px;

  padding: 30px;

  height: 330px;


  background-color: white;
  background: #FFFFFF;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);

  border-radius: 10px;

  width: 350px;
  box-shadow: 0px 3.69973px 16.6488px rgba(0, 0, 0, 0.25);

  border-radius: px;
  counter-increment: carousel-cell;

  counter-increment: carousel-cell;
}


.cardName2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 158.02%;
  /* identical to box height, or 32px */

  letter-spacing: 0.02em;

  color: #000000;
  position: absolute;
  left: 21%;

  top: 104px;

}

.INR2 {
  position: relative;
  top: px;

}

.INR10 {
  position: relative;
  top: -70PX;
  font-size: 30px;
  position: relative;
  left: "%"
}

.INR5 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 96.7%;
  /* identical to box height, or 13px */

  letter-spacing: -0.03em;

  color: #626262;

}

.INR3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 96.7%;
  /* or 23px */

  letter-spacing: -0.03em;
  position: relative;

  color: black;
}

.INR12 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 96.7%;
  /* or 23px */

  letter-spacing: -0.03em;
  position: relative;

  color: black;
}

.INR4 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 96.7%;
  /* or 23px */
  position: relative;
  left: -2.5%;
  letter-spacing: -0.03em;

  color: #7D7EC7;
}

.INR11 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 96.7%;
  /* or 23px */
  position: relative;
  left: 1%;
  letter-spacing: -0.03em;

  color: #7D7EC7;
}

.service-head {

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20.0999px;
  line-height: 42px;
  cursor: pointer;
  position: relative;
  top: -15px;
  color: #111111;
}


.view-text {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height */

  text-decoration-line: underline;

  color: #8D8FD1;
  position: relative;
  left: 40%;
  top: 20px;
}

.view-all2 {
  width: 100%;
  height: 50px;
  background: #F0EFF7;
  position: relative;
  top: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.view-all6 {
  /* width:  96%;
    height: 50px;
    background: linear-gradient(126.03deg, rgba(110, 115, 255, 0.41) 3.16%, rgba(151, 102, 255, 0.37) 78.4%);
    border-top-left-radius: 49.6241px;
    border-top-right-radius: 49.6241px;
    position: relative;
    top:480px;
    z-index: 99;
    left: ; */

  margin: auto;
}

.view-all3 {
  width: 100%;
  height: 50px;
  background: #F0EFF7;
  position: relative;
  top: -170px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

}

.view-all5 {
  width: 100%;
  height: 50px;
  background: #F0EFF7;
  position: relative;
  top: px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dash-edit {
  position: absolute;
  left: 72%;
  width: 25px;
  cursor: pointer;
}

.dash-edit2 {
  position: absolute;
  left: 82%;
  width: 25px;
  cursor: pointer;
}

.card-outside-container {
  width: 25%;
  margin: auto;
  border: 2px solid black;

}

.fourrow {
  display: flex;
  flex-wrap: wrap;
  margin: auto 220px;
  align-items: center;


}

.fourrow2 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 150px;

}

.row2 {
  width: 95%;
  height: auto;
  margin-bottom: 30px;
  margin-top: 300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.service-card3 {
  margin: auto;
  padding: 30px;
  padding-bottom: 20px;
  height: 330px;
  background: #FFFFFF;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);
  border-radius: 21.2581px;
  border-radius: 10px;
  position: relative;
  left: -px;

  margin-bottom: 20px;
  width: 350px;
  box-shadow: 0px 3.69973px 16.6488px rgba(0, 0, 0, 0.25);

  border-radius: px;

}

.service-card4 {
  margin: auto;
  height: 330px;


  width: 24%;

  background: #FFFFFF;
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);
  border-radius: 21.2581px;
  position: relative;





  box-shadow: 0px 3.69973px 16.6488px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.coun {
  margin: px;
  left: 5%;
}

.service-flex {
  display: flex;
  flex-wrap: wrap;
}

.rating-digit {

  position: relative;
  top: -13px;
  font-size: 12px;
  left: -31%
}

.rating-digit2 {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 158.02%;
  /* or 17px */

  letter-spacing: 0.02em;

  color: #000000;

  position: relative;
  top: -45px;
  font-size: 10px;
  left: -26.9%
}

.card-country2 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.2964px;
  line-height: 21px;
  color: #000000;
}

.seller-card-flag {

  position: relative;
  left: 7%
}

.seller-card-flag2 {

  position: relative;
  left: 4%;
  top: -10px;

}




@media (max-width:2000px) {
  .fourrow {

    width: 1500px;
    align-items: end;
    margin: 30px auto;
    margin-bottom: 20px;
  }

  .fourrow2 {

    width: 90%;
    margin: 130px auto;
    margin-bottom: 20px;

  }
}

@media (max-width:1500px) {

  .service-card3,
  .carousel-cell4 {

    left: 0px;
    margin: 20px auto;



  }

  .carousel-cell-top {

    margin-top: 10px;
  }

  .margin-profile {
    margin: auto;
  }

  .fourrow2 {
    width: 100%;
  }

  .fourrow {
    width: 1200px;
    /* border: 2px solid blue; */


  }

  .carousel-cell15 {}

}

@media (max-width:1400px) {

  .service-card3,
  .carousel-cell4 {

    left: 0px;
    margin: 20px auto;

  }

  .image-background {
    border-radius: 50%;
    padding: 5px;
    background: #E9E9F5;
  }
  .fourrow {
    width: 1200px;
    /* border: 2px solid blue; */


  }
}
@media (max-width:1281px) {
  .fourrow {
    width: 1200px;
   


  }
  
}





/* @media (max-width:1280px) {
  .fourrow {
    width: 90%;
    margin: auto;

  }

} */

@media (max-width:1027px) {




  .fourrow {
    width: 900px;
  }


  .slider5 {
    left: 0px;
    margin: 10px auto;
  }


}


@media (max-width:990px) {

  .service-card3,
  .carousel-cell4 {

    margin: 20px auto;
  }

  .service-comp-mobile {
    display: block;
  }

  .service-ul2 {
    width: 100%;
    border-radius: 10px;
  }

  .Service-ul {
    display: none;

  }
  .fourrow{
    width: 100%;
    margin:20px auto;
  }
  .carousel-cell-top{
    margin: 30px auto;
  }


}



@media (max-width:650px) {
  .user-banner {
    display: block;
    height: 600px;
    width: 100%;
    top: 100px;

  }

  .Service-li {
    font-size: 15px;
    margin-top: 10px;
  }

  .user-landing-heading2 {
    margin-top: 50px;
  }



  .service-ul2 {
    width: 100%;
  }

  .row2 {
    width: 100%;
  }

  .slider5 {
    left: 0px;
    margin: 10px auto;
  }

  .teda-meda {
    width: 80%;

    top: 1000px;

  }

  .user-illus {
    width: 70%;
  }



  .carousel-cell4 {
    margin: 20px;
  }



  .view-text {
    left: 0px;
  }

}

@media (max-width:500px) {


  .user-banner {
    height: 450px;
  }

  .service-pages-head {
    font-size: 25px;
  }

  .carousel-cell15 {

    margin: auto;

  }
}

@media (max-width:400px) {}

@media (max-width:350px) {
  .rating-digit {
    top: -10px;
  }

  .service-card3 {
    width: 100%;
  }

  .carousel-cell5 {
    width: 100%
  }

}

@media (max-width:321px) {


  .rating-digit {
    left: -60px;
    top: 0px;
  }

  .cardName2 {
    left: 70px;
  }



  .service-card3 {
    width: 100%;
  }

  .row2 {
    width: 100%;
  }

  .user-illus {

    margin-top: 35px;
  }

}