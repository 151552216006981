.sellerr {
  display: flex;
}

.seller-personal-image {
  position: relative;
  top: 100px;
}

.profile-flex {
  display: flex;
  margin-top: 10px;
}

.profile-image {
  flex-basis: 20px;

}

.seller-l-name {
  position: relative;
  top: 120px;
  font-family: 'Baloo Thambi 2', sans-serif;
}

.profile-pic {
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
}

.profile-image3 {
  width: 40px;
}

.flag-div {
  width: 80px;
  
  margin: auto;
}

.seller-loc {
  position: relative;
  top: 130px;
  font-family: 'Baloo Thambi 2', sans-serif;
}

.sellerr-info {
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 20%;
  background-color: #E9E9F5;
  height: 1900px;
  border: 1px solid #D9D9D9;

}

.seller-general {
  line-height: 30px;
  top: 10px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #8A92A6;
  margin-top: 10px;
  font-size: 12px;
}

.seller-loc-2 {

  top: 50px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: black;
  font-size: 15px;
  margin-top: 90px;
}

.seller-ping {
  line-height: 30px;
  top: 80px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: black;
  margin-top: 10px;
  font-size: 15px;
}

.seller-rating2 {
  line-height: 30px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  color: black;
  margin-left: 4px;
  font-size: 15px;


}

.seller-star-rate {
  margin-top: 10px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: black;
  font-size: 15px;
  margin-left: 30px;

}

.seller-known-lang {
  border: none;
  outline: none;
  width: 100%;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 41px;
  /* identical to box height */


  color: #000000;

}


.seller-flag {
  width: 9%;
  padding-top: 10px;
  padding-bottom: 15px;

  background: #F0EFF7;
  box-shadow: 0px 2.37708px 2.37708px rgba(0, 0, 0, 0.25);

  left: 5%;

  margin: 20px;
  border-radius: 50%;
  font-size: 13px;
  position: relative;
  top: -20px;


}

.flag-display {
  display: flex;
}

.flag-name {
  width: 10%;
  margin-top: 10px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 41px;
  /* identical to box height */
  position: relative;


  color: #000000;
}

.seller-dash-button {
  border: none;
  padding: 10px 40px 10px 40px;
  margin: 10px;
  background-color: white;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 51px */
  border-radius: 5px;

  color: #000000;
}

.seller-dash-button.active2 {
  background-color: #7D7EC7;
  color: white;
}

.avialable-funds {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 51px */


  color: #5F5F5F;

}

.last-seen {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 36px */


  color: #9A9A9A;
}

.my-funds {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 150%;
  /* identical to box height, or 180px */


  color: #000000;
}

.seller-drop-content {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  margin: 20px;
  color: #414141;
  width: 95%;

}

.sellerdrop {

  background: #FFFFFF;
  box-shadow: 0px 3.38539px 9.30982px rgba(0, 0, 0, 0.25);
  border-radius: 8.46348px;
  width: 80%;
  height: auto;
  text-align: left;
  margin: 40px auto;
  padding: 20px;
}

.seller-dis-full {
  position: relative;
  top: -450px;
  left: -13%;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  width: 110%;
  font-size: 15px;
  line-height: px;
  text-align: center;
  background-color:  #7075C4;
  border-radius: 10px;
  color:white;
  padding: 20px;
}

.seller-dis-full2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;

  width: 95%;
  margin: auto;
  text-align: center;
  color: #6F499C;
  margin-bottom: 20px;
}

.country-flag {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: -30px;
  margin: auto;
}

.seller-overflow {
  width: 90%;
  margin: auto;

  height: auto;

  position: relative;
  overflow: hidden;
  top: -530px;
  left: -10%;
}

.seller-overflow2 {
  width: 100%;
  height: auto;
  display: flex;
   flex-wrap: wrap;
  position: relative;
  overflow: hidden;

}

.seller-flag2 {
  background-color: #F0EFF7;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding-top: 20px;
  margin: auto;



}

.discipline-illus {
  margin-left: 5px;
  margin-bottom: 5px;
}

.flag-margin {
  margin-left: 30px;
  margin-right: 10px;
  margin-bottom: 70px;
  height: 200px;
}

.reviews-head {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  /* identical to box height */


  color: #8A92A6;
}

.rating-star {
  margin-top: 10px;
}

.name-profile {
  font-family: 'Baloo Thambi 2';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 38px;
  /* identical to box height */


  color: #000000;

}

.profile-chat {
  position: relative;
  top: -20px;

  padding: 10px 20px 10px 20px;
  border: none;

  font-family: 'Gotham Book ', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 7px;
  /* identical to box height */
  margin-top: 10px;
  margin-bottom: 10px;
  color: #FFFFFF;

  background: #7D7EC7;
  border-radius: 0.985916px;
}

.seller-discription3 {
  width: 200px;
  border: 1px solid #D9D9D9;
  height: auto;
  position: relative;

  top: 140px;

  /* padding-bottom: 10px; */
  background-color: white;
  text-align: left;
  padding: 20px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 20px auto;
}

.seller-discription4 {
  width: 100%;
  border: 1px solid #D9D9D9;
  height: auto;
  margin-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  text-align: left;
  padding-left: 20px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.profile-services-head {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 38px;
  /* identical to box height */


  color: #8A92A6;
  position: relative;
  left: -54%;

}

.seller-hr {
  transform: rotate(90deg);
  position: relative;
  left: 43%;
  top: -160px;

}

.seller-service-pic {
  flex-basis: 70%;
  position: relative;
  left: 10%;
  top: -0px;
  background-color: white;
  height: 700px;


}

.s-pic1 {
  width: 30%;
  height: 300px;

  border-radius: 5px;
  position: relative;
  left: -10%;


  background: #E9E9F5;
  margin: 10px;
}

.s-pic2 {
  width: 33%;
  height: 200px;
  border: 1px solid black;

}

.seller-pic-sec-1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  top: -400px;

  margin: auto;
  left: -5%;

}

.s-pic3 {
  width: 33%;
  height: 200px;
  border: 1px solid black;
  position: relative;
  left: -10%;
  margin-top: 30px;
}

.s-pic4 {
  width: 33%;
  height: 200px;
  border: 1px solid black;
  margin-top: 30px;
}

.seller-pic-sec-2 {

  display: block;
  flex-basis: 15%;



  position: relative;
  left: 10%;
  top: -300px;
  box-sizing: border-box;
  height: 300px;


}

.ratingss {
  text-align: center;
}

.rating-8 {
  width: 100%;
  background-color: white;
  height: auto;

  position: relative;

  left: 19%;

  text-align: left;
  padding: 10px;
  top: 40px;
}

.rating-star-1 {
  margin-left: 10px;
}

.rating-mobile {
  width: 90%;
  background-color: white;
  height: auto;

  margin-top: 10px;


  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: left;
  padding: 10px;

}

.rating-9 {
  width: 90%;

  height: auto;

  position: relative;
  left: 20%;
  top: 80px;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px;

}

.rating-seemore {

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  text-decoration-line: underline;

  color: #8A92A6;
  margin-left: 29%;
  margin-top: 10px;
}



.rating-10 {

  background-color: white;
  height: 250px;
  position: relative;

  top: 80px;
  text-align: left;

  border-radius: 5px;

  padding: 10px;

}

.seller-name {
  margin: auto;
  font-family: "Inter", sans-serif;
  color: rgba(0, 0, 0, 0.648);
}

.dash-button2 {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 260px;
}

.seller-rating {
  position: relative;
  left: -25%;
  top: -20px;

}



.dash-getPayout {
  background: #7D7EC7;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  margin-top: 20px;
  color: white;
  border: none;
  width: 40%;
}

.sellerDash2 {


  width: 80%;
  margin: 280px auto;
}

.dash-edit-color {
  border-radius: 50%;
  background-color: #DEDAF9;
  border: none;
  margin-left: 5px;
  height: auto;

}

.rating-content {

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  line-height: 1px;

  color: #8A92A6;

}

.seller-card-pic {
  position: absolute;
  top: -30px;
  left: 90%;
}

.dash-name {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 40px;

  /* or 106px */

  letter-spacing: 0.1em;

  color: #7D7EC7;
}

.dash-photo {
  width: 120px;
  height: 120px;
  background-color: #32326D;
  border-radius: 50%;
  margin: auto;
  position: relative;
  top: -60px;
}

.dash-rating {

  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  /* identical to box height */


  color: #7D7EC7;
}

.seller-service-dis {
  position: relative;
  top: px;
  width: 75%;
  left: -4%;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  text-align: center;

  color: #707077;

}


.sec-2-flex {
  display: flex;
}

.seller-service-picss {
  width: 90%;
  height: 120px;

  position: relative;
  top: 10px;
  border-radius: 10px;
  background-color: white;
  left: 5%;

}

.dropDown {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */


  color: #7D7EC7;
}

.dropDown:hover {
  background: rgba(112, 117, 196, 0.49);
  color: white
}

/* .how-it-works{
  position: relative;
  left: 50%;
  font-weight: 300;
  color: black;
  font-style:italic;
                  color:black;
                  text-decoration:none;
                 
                  
                  font-weight:500
} */
.brand-pic {
  position: relative;
  left: 90%
}

.navbar-brand {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 77px;
  /* identical to box height */
  position: relative;
  left: 3%;

  color: #303035;
}

.seller-personal-mobile {
  display: none;


}

.dash-button {
  position: relative;
  top: 180px;
}

.seemore-button {
  border: none;
  background-color: transparent;
  width: 90%;
}

.Dash-head {
  width: 80%;
  height: auto;
  background: linear-gradient(126.03deg, #E7E4FE 3.16%, rgba(218, 201, 255, 0.11) 78.4%);
  border-radius: 50px;
  position: relative;
  top: 150px;
  margin: auto;
  padding: 10px;

}

.dash-hosted {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  /* identical to box height, or 60px */

  letter-spacing: -0.03em;

  color: #9747FF;
  margin-left: 45px
}

.create-service {
  border: none;
  background: #9747FF;
  padding: 10px 15px 10px 15px;


  border-radius: 5px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 51px */


  color: #FFFFFF;
}

.ongoing {

  text-align: left;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  /* identical to box height, or 60px */

  letter-spacing: -0.03em;

  color: #9747FF;

  margin-left: 70px;
  margin-top: 70px;

}

.dash-view {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 36px */
  float: right;
  border: none;
  color: #6B7280;
  text-align: right;
  background: transparent;
  text-decoration: underline;
  margin-right: 80px;
}

.draft {


  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 150%;
  /* identical to box height, or 60px */

  letter-spacing: -0.03em;

  color: #9747FF;
  text-align: left;
  margin-left: 70px;
  margin-top: 70px;
}

.manage-service {
  border-color: #9747FF;
  padding: 8px 20px 8px 20px;
  background: none;

  border-radius: 5px;
  margin: auto;
  margin-right: 30px;
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 51px */


  color: #7D7EC7;
}

.level {
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.sellerpersonal-info-drop {
  background: #EDEBFE;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 2px;
  margin-left: 20px;
  /* display: flex;
  flex-wrap: wrap; */
  font-size: 20px;
  padding: 4px 10px 4px 10px;

  color: #7275C4;
 
}

.service-head-profile {
  position: relative;
  top: 30px;
  text-align: center;


  left: -5px;
  font-family: 'Baloo Thambi 2', sans-serif;
  color: black;
 margin: auto;
 
}

.seller-reviews-heading {
  position: relative;
  left: 50%
}

.seller-review-mobile {
  font-family: Baloo Thambi 2;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 31px;
  color: #8A92A6;



}

.drop-flex {
  display: flex;
 
}

.profile-hr2 {
  position: relative;


  top: 40px;
  width: 90%;
}

.cardName3 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #000000;
  position: absolute;
  left: 23.9%;
  top: 121px;

}


.deleteOptionMain {
  position: absolute;
  z-index: 99;
  width: 100%;

  left: 0px;
}

.delete-my-acc {
  display: flex;
  justify-content: space-between;
}

.deletemyacc {
  flex-basis: 20%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 133.02%;
  /* identical to box height, or 27px */
  cursor: pointer;
  text-decoration-line: underline;

  color: #7D7EC7;
}

.revenew {
  display: flex;
  margin: 50px auto;
  justify-content: space-between
}

.savecancel {
  flex-basis: 30%;
}

.profile-margin {
  margin-bottom: 10px;
}

.dash-rating-head {
  margin-bottom: 5px;
}

.deleteCancel {
  margin-top: 20px;
}

.deleteOption {
  background-color: white;
  z-index: 99;
  height: auto;
  margin: auto;
  width: 320px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  padding: 20px;
  text-align: center;
}

.edit-dash {
  width: 5px;
  margin: auto;
  height: 10px;

}

.deleteOption-head {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 217.02%;
  /* or 76px */
  background-color: white;
  text-align: center;

  color: #7D7EC7;
}

.deleteOption-head2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 217.02%;
  /* or 43px */

  text-align: center;

  color: #000000;

}

.button-dash-Cancel {
  background: #7D7EC7;
  border: 0.742317px solid #7D7EC7;
  border-radius: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 20px 5px 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */


  color: #FFFFFF;

}

.dash-delete-illus {
  background: #7D7EC7;
  border-radius: 50%;
  padding: 8px;
  width: 40px;
}

.button-dash-delete {
  border: 1px solid #7D7EC7;
  border-radius: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 5px 20px 5px 20px;
  line-height: 133.02%;
  background: transparent;
}

.seller-countries-spes {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
   
text-align: left;
  line-height: px;
  margin-top: 60px;
  margin-bottom: 65px;
  margin-left: 50px;
  color: black;


 

}
.seller-countries-spes2 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
   font-size: 25px;
text-align: center;
  line-height: px;
  margin-top: 30px;

  color: black;


 

}

@media (max-width:1280px) {


  .rating-8,
  .rating-9 {
    width: 70%;
  }

}

@media (max-width:1024px) {


  .rating-8,
  .rating-9 {
    width: 82%;
  }

}

.button-mobile {
  display: none;
}

@media (max-width:990px) {
  .sellerr {
    display: none;
  }

  .fot-hide {
    display: none;
  }

  .Dash-head {
    width: 100%;
    top: 200px;
  }


  .button-phone {
    display: none;
  }

  .button-mobile {
    display: block;
    margin-top: 20px;
  }

  .seller-personal-mobile {
    display: block;

  }

  .ongoing {
    left: 0px;
    margin: 70px auto;
    text-align: center;
  }

  .draft {
    left: 0px;
    margin: 70px auto;
    text-align: center;
  }

  .seller-main {
    background: linear-gradient(180deg, #C68DFF 0%, rgba(140, 186, 255, 0.58) 100%);
  }





  .dash-button {
    top: 230px;
  }

  .dash-button2 {
    display: block;
    top: 300px;
  }

  .dash-hosted {
    margin-left: 0px;
  }

  .profile-chat {

    margin-left: 0px;
  }

  .create-service {
    margin: auto;
  }

  .manage-service {
    margin: auto;
    margin-left: 3px;
  }


  .dash-view {
    float: none;
    margin-right: 0px;
  }
}

@media (max-width:610px) {




  .delete-my-acc {
    display: block;
  }

  .deletemyacc {

    width: 300px;


    margin: 20px auto;
    text-align: center;
    margin-bottom: 50px;
  }

  .profile-save2 {
    display: block;
    margin: auto;
    margin-bottom: 20px;

  }

  .profile-cancel2 {
    display: block;
    margin: auto;
  }

  .dash-name {
    font-size: 20px;
  }

  .revenew {
    display: block;
  }

  .dash-getPayout {
    width: 80%;
  }
}

@media (max-width:500px) {


  .Dash-head {
    width: 99%;
  }



}

@media (max-width:360px) {
  .dash-name {
    font-size: 23px;
    top: -60px;
  }

  .dash-rating {
    font-size: 23px;
    top: -80px;
  }



  .cardName3 {
    top: 110px;
  }
}