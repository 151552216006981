.main-text-order {
    position: relative;
    top: 150px;
    width: 90%;
    font-size: 40px;
  
    margin: auto;
    color: rgba(125, 126, 199, 1);
    display: flex;
}
.maindiv1{
    background: linear-gradient(180deg, #E6E5FF 0%, rgba(244, 244, 255, 0) 109.07%, rgba(244, 244, 255, 0) 134.58%);
}

.text-order {
    position: relative;
    top: 150px;
  width: 90%;
  margin: auto;
  display: flex;
    font-size: 40px;
    text-align: left;
    margin-bottom: 20px;
    color: rgba(125, 126, 199, 1);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.orderpic{
    width: 70px;
}

.outerback-1 {
    
    text-align: left;
    width: 90%;
    height:600px;
    position: relative;
    
}

.table-style {
    border-radius: 20px;
}

.wide-cell1 {
    padding: 10px;
}



.button{
    background-color: rgba(125, 126, 199, 1);
    margin: 0;
    width: 120px;
    border: none;
    border-radius: 5px;
    color: white;
    
}

.switchbtn {
    position: relative;
    top: 170px;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 90%;

  

  margin: auto;
    
   
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.maindiv {
    background: linear-gradient(180deg, #E6E5FF 0%, rgba(244, 244, 255, 0) 134.58%);
}
.my-order-head{
    flex-basis: 30%
}
.outerback {
    height: 500px;
    max-width: 100%;
    overflow: visible;

    position: relative;
}

.t-style {
    border-radius: 20px;
}

.wide-cell {
    padding: 10px;
}

td {
    font-weight: 600;
}

#mytable {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: #FFFFFF;
       
 
}
.warpper{
    max-width: 90%;
    margin: auto;
    height: 600px;
    overflow-x: scroll;
   padding-top: 150px;
    position: relative;
    top: -180px;
}
@media(max-width:610px){
    .main-text-order{
        font-size: 30px;
    }
    .my-order-head{
        flex-basis: 70%;
    }
    .switchbtn{
        display: block;
        margin: auto;
        width: 30%;
        
    }
    
    .outerback-1{
        height: 800px;
        width: 100%;
    }
    
}

@media (max-width:391px) {

    .main-text-order{
        font-size:20px ;
    }
    .text-order{
        font-size: 25px;
    }
    .my-order-head{
        width: 90%;
    }
    .switchbtn{
        width: 50%;
    }

}
@media (max-width:361px) {

    .main-text-order{
        font-size:20px ;
    }
    .my-order-head{
        width: 100%;
    }

}

.butt{
    width: 200px;
    height: 100px;
    border: none;
    background-color: rgba(120, 121, 193, 0);
    font-size: 30px;
}
.butt.active2{
    color: rgba(125, 126, 199, 1);
    border-bottom: 2px solid 
    #7478C5;
}

