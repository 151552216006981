.user-discription{
    width: 80%;
      border: 1px solid #D9D9D9;
      height: auto;
      position:relative;
      left: 10%;
      top: 160px;
      background-color: white;
      text-align:left;
  padding: 15px;
  
  
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  }
  .user-discription2{
    width: 80%;
      border: 1px solid #D9D9D9;
      height: auto;
      position:absolute;
      z-index: 99;
      left: 10%;
      top: 160px;
      background-color: white;
      text-align:left;
  padding: 15px;
  
  
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  }
  .user-dash-pc{
    display: flex;
  }
  .suggestion{
    font-family: 'Inter',  sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15.063px;
  line-height: 150%;
  background: #EDEBFE;
  border-radius: 9.5315px;
  width: 10%;
  position: absolute;
  left: -5%;
  top: 34px;
  
  color: #7275C4;
  }
  .suggestion2{
    font-family: 'Inter',  sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15.063px;
  line-height: 150%;
  background: #EDEBFE;
  border-radius: 9.5315px;
  width: 20%;
  position: absolute;
  left: 10%;
  top: 80px;
  
  color: #7275C4;
  }
  .my-name{
    
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 44px;
  /* identical to box height */
  left: -17%;
  position: relative;
  color: #131313;
  
  }
  
  .my-save{
    border: none;
    background: #5D66B5;
    color: white;
    padding: px 10px px 10px;
    border-radius: 11px;
    width: 30%;
    
  font-family: 'Gotham Book ',  sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height */
  
  margin: 15px;
  color: #FFFFFF;
  }
  .my-cancil{
    border: none;
    background:white;
    color:  #5D66B5;
    padding: px 10px px 10px;
    border-radius: 5px;
    width: 30%;
    border: 1px solid #5D66B5;
  border-radius: 11px;
  font-family: 'Gotham Book ',  sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px; 
  }
  .my-cross{
    position: absolute;
    left: 90%;
    background: none;
    border: none;
    top: -3px;
    font-family: 'Baloo Thambi 2',  sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  line-height: px;
  /* identical to box height */
  
  
  color: #7D7EC7;
  }
  .minus{
  
    background-repeat: no-repeat;
    background-position: 98%;
    display: none;
  
    
  }
  #my-minus{
    display: none;
  }
  .underline{
    width: 25%;
    height: 25px;
    background: #E1E0F6;
    position: relative;
    opacity: 70%;
    left: -20%;
    top: -390px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  
  }
  .underline4{
    width: 40%;
    height: 18px;
    background: #E1E0F6;
    position: relative;
    opacity: 70%;
   
    top: 81px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  
  }
  .underline2{
    width: 70%;
    height: 25px;
    background: #E1E0F6;
    position: relative;
    opacity: 70%;
    left: -20%;
    top: -518px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  
  }
  .underline3{
    width: 115%;
    height: 10px;
    background: #E1E0F6;
    position: relative;
    opacity: 70%;
    left: -20%;
    top: -3px;
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  }
  .my-reviews-head{
    position:relative;
    left:-58%;
    top:-370px;
    font-weight:bold;
    font-family: 'Baloo Thambi 2',  sans-serif
  }
  .my-reviews-head2{
    position:relative;
    left: -23%;
    top: 100px;
    font-weight:bold;
    font-family: 'Baloo Thambi 2',  sans-serif
  }
  
  .my-input{
    background: rgba(212, 214, 238, 0.33);
  border: 1px solid #8B8B8B;
  border-radius: 6px;
  padding: 5px 10px 5px 10px;
  position: relative;
  top: -5px;
  }
  .edit-name{
    display: none;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 20px;
  width: 18%;
  height: 180px;
  position: absolute;
  left: 1%;
  top: 260px;
  z-index: 99;
  }
  .edit-photo{
    display:none;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-top: 40px;
  width: 18%;
  height: 250px;
  position: absolute;
  left: 1%;
  top: 220px;
  z-index: 99;
  }
  .edit-name2{
    display:none;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 40px;
    width: 70%;
    height: 250px;
    position: absolute;
    left: 15%;
    top:140px;
    z-index: 99;
  }
  .edit-photo2{
    display:none;
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 40px;
    width: 70%;
    height: 250px;
    position: absolute;
    left: 15%;
    top: 85px;
    z-index: 99;
  }
  .user-dash-head{
    position: relative;
    top: -500px;
    
    font-weight:bold;
    font-family: 'Baloo Thambi 2',  sans-serif;
    left: -36%;
  
  }
  .user-dash-head2{
    position: relative;
  text-align: left;
  margin: 5px;
  top: 10px;
    font-size: 18px;
    font-weight:bold;
    font-family: 'Baloo Thambi 2',  sans-serif;
   
  
  }
  .user-l-name{
    position: relative;
    top: -30px;
    font-family: 'Baloo Thambi 2',  sans-serif;
    font-weight: 600;
  }
  .seller-name{
 
font-family: 'Baloo Thambi 2',  sans-serif;
font-style: normal;
font-weight: 600;
font-size: 25px;
left: 1%;
line-height: 31px;

color: #6F499C;
position: relative;
top: -30px;
  }
  .user-head2{
    position: relative;
    top: -490px;
    font-size: 20px;
    font-family: 'Baloo Thambi 2',  sans-serif;
  }
  
  .user-head3{
    position: relative;
    top: 30px;
    font-size: 12px;
    font-family: 'Baloo Thambi 2',  sans-serif;
  }
  .my-reviews{
    display: flex;
    width: 100%;
  
    position: relative;
    left: -15%;
    top: -400px;
    
  }
  .my-reviews2{
    display: flex;
    width: 100%;
  
    position: relative;
    top: 150px;
 
   
    
  }
  .my-photo{
    width: 13%;
    height: 100px;
    border-radius: 50%;
    
  background: #E1E0F5;
  }
  .reviews-dis{
    border: 1px solid gray;
  width: 280%;
  padding: 5px;
    height: 120px;
    position: relative;left: 4%;
    top: -8px;
  
  }
  .reviews-dis2{
    border: 1px solid gray;
  width: 100%;
  padding: 5px;
    height: 80px;
    position: relative;left: 4%;
    top: -8px;
  
  }
  .my-photo2{
    width: 15%;
    height: 60px;
    border-radius: 50%;
    margin-left:  5px;
  background: #E1E0F5;
  position: relative;
  top: -35px;
  }
  .country-drop{
    width: 130%;
    height: 30px;
    position: relative;
    left: -10%;
    border: 1px solid #8B8B8B;
    
  }
  #my-minus2{
    display: none;
  }
  .minus2{
  display: none;
  }
  .country-drop3{
    width: 90%;
    height: 45px;
border-radius: 5px;
    position: relative;
    
    border: 1.10632px solid #B5B5B5;
   
  }
  .country-drop15{
    width: 89.5%;
    height: 45px;

    position: relative;
    
    border: 1.10632px solid #B5B5B5;
  
  }
  .country-drop11{
    width: 135%;
    height: 45px;
border-radius: 5px;
    position: relative;
    
    border: 1.10632px solid #B5B5B5;
  
  }
  .country-drop12{
    width: 100%;
    height: 45px;
border-radius: 5px;
    position: relative;
    
    border: 1.10632px solid #B5B5B5;
   
  }
  .country-drop17{
    width: 190%;
    height: 45px;
border-radius: 5px;
    position: relative;
    
    border: 1.10632px solid #B5B5B5;

  }
  .seller-personal-mobile{
    width: 100%;
    height: auto;
    position: relative;
    
    top: 200px;
    background: #F5F6F8;
border-radius: 30px 30px 0px 0px;
    border-radius: 25px;
  }
  .user-dash-mobile{
    width: 100%;
  height: 159px;
  position: relative;
  display: none;
  top: 140px;
  
  background: rgba(233, 233, 245, 0.72);
  border-radius: 25px;
  
  }
  .user-personal-image{
    position: relative;
    top: -50px;
  }
  .seller-personal-image2{
    position:relative;
    top: 140px;
    left: -10%;
  
    background: #D4D6EE;
    box-shadow: 0px 1.78497px 1.78497px rgba(53, 53, 53, 0.25);
    padding: 5px;
    border-radius: 50%;
    width: 10%;
  }
  .seller-personal-image3{
    position:relative;
    top: -10px;
    left: -7%;
  
    background: #D4D6EE;
    box-shadow: 0px 1.78497px 1.78497px rgba(53, 53, 53, 0.25);
    padding: 5px;
    border-radius: 50%;
    width: 10%;
  }
  .edit-pic-icon{
    position:relative;
    top: 40px;
    left: -7%;
  
    background: #D4D6EE;
    box-shadow: 0px 1.78497px 1.78497px rgba(53, 53, 53, 0.25);
    padding: 5px;
    border-radius: 50%;
    width: 10%;
  }
  .dash-drop{
    margin-top: 18px;
                                    float: right;
                                    width: 5%;
  }
  .dash-drop2{
    margin-top: 18px;
                                    float: right;
                                    width: 15px;
  }
  .drop-in-head{
    float:left;
    margin-left:10px;
    margin-top: 10px;
    font-family:"Baloo Thambi 2";
    color: #000000;

  }
  .country-drop2{
    width: 120%;
    height: 30px;
    margin-right: 10px;
    border: 1px solid #8B8B8B;
    position: relative;
    margin-left:  130px;
    
  }
  .country-drop4{
    width: 80%;
    height: 30px;
    margin-right: 10px;
    border: 1px solid #8B8B8B;
    position: relative;
    margin:auto;
    
  }
  .country-drop-div{
    width: 60%;
    height: 300px;
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
    z-index: 99;
    left: -5%;
  }
  .dropdowndiv{
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  .dropdowndiv2{
    display: flex;
    justify-content: space-between;

  }
  .country-drop-div99{
    width: 90%;
    height: 300px;
   
    background:#F9F8FF;
    position: relative;
    top: px;
    overflow-y: scroll;
    
    
  }
  .country-drop-div::-webkit-scrollbar{
    width: 5px;
  }
  .country-drop-div4::-webkit-scrollbar{
    width: 5px;
  }
  .country-drop-div2{
    width: 60%;
    height: 300px;
    position: relative;
    left: 62%;
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
  }
  .country-drop-div3{
    width: 50%;
    height: 300px;
    position: relative;
   
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
  }
  .country-drop-div4{
    width: 60%;
    height: 300px;
    position: relative;
    left:62% ;
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
    z-index: 99;
  }
  .country-drop-div5{
    width: 60%;
    height: 300px;
    position: relative;
    left:62% ;
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
  }
  .country-drop-div6{
    width: 60%;
    height: 300px;
    position: relative;
    left:62% ;
    display: none;
    background:#F9F8FF;
    position: absolute;
    overflow-y: scroll;
  }
  
  
  .my-selects{
    position: relative;
    top: -500px;
  display: flex;
  justify-content: space-between;
    margin-top: 35px;
  }

 
  .country-drop4{
    margin-top: 30px;
  }
  .my-country{
    font-family: 'Inter',  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 176.52%;
  /* or 35px */
  
  letter-spacing: 0.005em;
  
  color: #575757;
  }
  .my-country4{
    font-family: 'Inter',  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 176.52%;
  /* or 35px */
  
  letter-spacing: 0.005em;
  
  color: #575757;
  }
  .my-check{
    height: 20px;
    width: 20px;
    background-color: #eee;
    margin-top: 10px;
  }

  .university:hover{
    background-color: white;
  }
  .agency{
    position: relative;
    left: -36%;
   
    font-size: 19px;
  }
  .my-rating{
    position: relative;
    left: -35.9%;
    top: -13px;
    width: 20%;
  }
  .my-rev{
    position: relative;
    top: 40px;
  }
  #burger{
  display: none;
  }
  .user-discription2{
    display: none;
  }
  @media (max-width:990px) {
    .country-drop-div{
      left: 10%;
      width: 80%;
      top: 78px;
    }
    .user-dash-pc{
      display: none;
    }
    .container6{
      display: none;
    }
    #burger{
      display: block;
    }
    .user-dash-mobile{
      display: block;
    }
    .country-drop-div4{
      left: 10%;
      width: 80%;
    }
    
  }