.name2 {
  background-image: url('../../images/name2.webp');
  background-position: 15px 6px;
  background-repeat: no-repeat;
  background-size: 4%;
}
body{
  box-sizing: border-box;
}
.email2 {
  background-image: url('../../images/mail.webp');
  background-position: 15px 6px;
  background-repeat: no-repeat;
  background-size: 4%;
}

.tel2 {
  background-image: url('../../images/phone.webp');
  background-position: 15px 6px;
  background-repeat: no-repeat;
  background-size: 4%;
}

.why-choose {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 72px;
  /* identical to box height */
  padding-top: 50px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #FFFFFF;
}

/* how it works */
.how-head {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 131%;
  /* or 84px */


  text-align: center;
  text-transform: uppercase;

  color: #32326D;
}

.step-content1 {
  position: absolute;
  left: 70%;
  top: 110px;
}

.step-content2 {
  position: absolute;
  left: 30%;
  top: 270px;
}

.step-content3 {
  position: absolute;
  left: 70%;
  top: 450px;
}

.submit2 {
  position: relative;

  top: 10px;



  margin: 20px auto;


  width: 130px;

  font-family: "inter";
  background: #7D7EC7;
  border: 1px solid #7B7B7B;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 20px 5px 20px;

  font-weight: bolder;
  color: white;
}

.point2 {
  position: absolute;
  left: -1.5%;
  top: 65px;
  width: 3%;

}

.point {
  position: absolute;
  top: -5px;
  left: 80%;
  width: 2%;
}

.point-last {
  position: absolute;
  top: 164px;
  left: 82%;
  width: 2%;
}

.point3 {
  position: absolute;
  left: 99%;
  top: 70px;
  width: 3%;
}

.point1 {
  position: absolute;
  left: 99%;
  top: 70px;
  width: 3%;
}

.how-content {
  display: block;
  width: 880px;
  height: 600px;

  position: relative;
  top: 200px;
}


.how-content1 {
  width: 440px;

  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: 2px dashed black;
  position: absolute;
  left: 11%;

  top: 60px;
  padding: 0px;
  padding: 8px;

  height: 170px;
  border-radius: 150px;

  text-align: center;
}


.how-content4 {
  width: 100%;



  left: 11%;
  top: 50px;
  padding: 0px;
  padding: 8px;

  height: 150px;
  border-radius: 150px;

  text-align: center;
}

.how-content-mobile {

  display: none;
  width: 95%;
  height: 600px;
  margin: auto;
  position: relative;
  top: 200px;
}

.how_1 {

  padding: 20px;

  margin: 10px auto;
  margin-right: 10px;
  /* backgroud:EAE8F9 */
  height: 130px;
  border-radius: 150px;
  background-color: #EAE8F9;

}

.how_5 {

  margin-left: 15px;
  padding: 20px;
  /* backgroud:EAE8F9 */
  margin: 10px auto;
  margin-left: 10px;
  height: 130px;
  border-radius: 150px;
  background-color: #EAE8F9;

}

.how_3 {
  height: auto;
  margin-left: 15px;
  padding: 20px;
  /* backgroud:EAE8F9 */
  margin: 10px auto;
  margin-right: 10px;

  height: 130px;
  border-radius: 150px;
  background-color: #EAE8F9;

}

.step-font1 {
  color: #626262;
}

.step-font2 {
  font-size: 25px;
  color: #7D7EC6;
}

.how_2 {
  height: auto;
  padding: 20px;
  /* backgroud:EAE8F9 */
  height: 130px;
  border-radius: 150px;
  background-color: #EAE8F9;
}

.how-content-head {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 101%;
  /* or 52px */

  text-align: center;
  text-transform: uppercase;

  color: #7275C4;
}

.how-content-head2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 138.5%;
  /* or 33px */
  width: 260px;
  text-align: center;

  color: #7275C4;

}

.decide {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 122%;
  /* or 78px */

  text-align: center;
  letter-spacing: 0.06em;

  color: #000000;

}

.decide2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 39px;
  /* identical to box height */


  color: #000000;
}

.how-content2 {
  width: 440px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 2px dashed black;
  position: absolute;
  left: 42%;

  top: 230px;
  padding: 8px;

  border-radius: 150px;
  height: 170px;
  text-align: center;
}

.how-content5 {
  width: 100%;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 2px dashed black;

  left: 45%;

  top: 200px;
  padding: 8px;

  border-radius: 150px;
  height: 150px;

}

.how-content3 {
  width: 440px;
  height: 170px;

  position: absolute;

  top: 400px;
  padding: 0px;
  padding: 8px;
  left: 11%;
  border-radius: 150px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: 2px dashed black;
  text-align: center;
}

.how-content6 {
  width: 100%;
  height: 150px;



  top: 350px;
  padding: 0px;
  padding: 8px;
  left: 11%;
  border-radius: 150px;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-right: 2px dashed black;
  text-align: center;
}

.how-create-head {
  position: relative;
  right: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  /* or 52px */
  margin-top: 5px;


  text-align: center;
  text-transform: uppercase;

  color: #7275C4;
}

.how-create-head-2 {
  position: relative;
  left: 30%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 134%;
  /* or 32px */
  width: 90%;
  text-align: center;

  color: #7275C4;
}

.how-create-head-3 {
  position: relative;
  top: -10px;
  font-size: 13px;
  line-height: 5px;
}

.how-illus {



  
  border-radius: 50%;

  background: linear-gradient(295deg, #E9E9E9 24.75%, #FFFFFF 100%);
  box-shadow: 2.44444px 4.88889px 7.33333px rgba(0, 0, 0, 0.27), -20.8249px -9.71083px 45.9556px rgba(51, 51, 51, 0.1105), inset 4.7666px 2.2227px 5.25936px #E4E4E4, inset -4.7666px -2.2227px 5.25936px #FFFFFF;
  padding: 10px;
}

.how-content-sec-2 {
  width: 90%;
  height: 400px;
  background: #7D7EC7;
  margin-top: 300px;
}

.how-flex {
  display: flex;

  text-align: justify;

}

.how-flex1 {
  flex-basis: 380px;
  margin: auto;
  height: 200px;
  text-align: justify;


}

.how-flex2 {
  flex-basis: 380px;
  height: 200px;

  align-items: center;
  margin: auto;
  text-align: justify;

}

.how-background {

  border-radius: 12px;
  position: relative;
  top: 120px;
  padding-top: 10px;
}

.how-flex3 {
  flex-basis: 380px;
  height: 200px;
  margin: auto;


}

.how-lead {
  margin-top: 200px;
  margin-bottom: 300px;
}

.how-form {
  width: 40%;
  position: relative;
  margin: 10px;


  border: 1px solid #7b7b7b2b;

  outline: none;

  padding-left: 50px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.how-lead-form {
  position: relative;
  top: 30px;
}

.how-mobile1,
.how-mobile2,
.how-mobile4 {
  display: none;
  color: #7275C4;

}

.how-main {
  background: linear-gradient(180deg, rgba(236, 233, 255, 0.6) 0%, rgba(234, 232, 249, 0) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  top: 100px;
}

.how-color {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  text-align: center;

  color: #FFFFFF;
}

.how-color2 {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;

  text-align: center;

  color: #FFFFFF;
}

.how-line {
  position: absolute;
  left: 38%;
  top: 20px;
}

.step1 {
  position: relative;
  color: #7D7EC6;
  font-size: 25px;
  top: 70px
}

.step2-2 {

  color: #7D7EC6;
  font-size: 25px;
  position: relative;
  top: 220px;
  float: left;
  left: -35%;
}

.step2 {
  position: relative;
  top: 200px;
  float: left;
  left: -30%;
  color: #7D7EC6;
  font-size: 25px;

}

.how-mobile {
  display: none;

}

.how-head2 {
  color: #A7A7EC;
  

  font-family: 'Poppins';
  font-style: normal;

  font-weight: bolder;
  font-size: 80px;
  line-height: 131%;
}

.step-3 {
  position: relative;
  top: -100px;
  left: 80%;
  color: #7D7EC6;
  font-size: 25px;
}

.step-3-3 {
  position: relative;
  top: -120px;
  left: 80%;
  color: #7D7EC6;
  font-size: 25px;
}


@media (max-width:1280px) {
  .how-content-sec-2 {
    width: 90%;
  }

}

@media (max-width:1024px) {
  .how-content {
    width: 80%;
  }

  .how-hr {
    display: none;
  }
}

@media (max-width:990px) {
  .how-content {
    width: 100%;
  }

  .how-head2 {
    top: 150px;
  }

  .how-flex {
    display: block;
    height: 900px;

  }

  .how-content-sec-2 {
    height: 1100PX;
  }

  .how-flex1,
  .how-flex2,
  .how-flex3 {
    margin: 80px auto;
    width: 60%;
  }

}

@media (max-width:600px) {


  .submit2 {
    width: 30%;
    margin: auto;
  }

  .how-content1 {
    left: 2%;
  }

  .how-content2 {
    left: 39%;
  }

  .how-content3 {
    left: 2%;
  }

  .how-content-head {
    font-size: 12px;
  }

  .how-head2 {
    top: 150px;
  }

  .how-lead {
    width: 95%;
  }

  .how-form {
    width: 90%;
  }

  .how-content {
    display: none;
  }

  .how-content-mobile {
    display: block;
  }

}


@media (max-width:600px) {



  .how-flex1,
  .how-flex2,
  .how-flex3 {
    margin: 80px auto;

  }

  .how-form {
    width: 90%;
  }

  .how-head {
    font-size: 20px;
  }

  .how-head2 {
    font-size: 25px;
  }

  .how-content-head {
    font-size: 12px;
  }

  .step-font2 {
    margin-top: 40px;
    margin-bottom: 20PX;
  }

}

@media (max-width:500px) {
  .how-content4 {

    height: 160px;
  }

  .how_2 {
    height: 150px;
    width: 100%;
  }
  

  .how-content-head2 {
    width: 90%;

    margin-top: 20px;
  }

  .how-content-mobile {
    width: 100%;
    height: auto;
  }


  .how-color {
    margin-top: 20px;
  }

  .how-lead {
    margin: 100px auto;

  }
}

@media (max-width:360px) {
  .how-content-head2 {
    width: 80%;
    font-size: 7px;
  }



}