.become-head {
  font-family: 'Gotham Book ', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 99.5%;
  position: relative;
  top: 170px;
  text-transform: uppercase;
  color: #5B5CB8;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  right: 4px;
}

.become-illus {
  position: absolute;
  left: 8%;
  width: 6%;
  top: 730px;
}

.button-div {
  width: 300px;
  margin: auto;

}

.become-head2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 133.52%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  position: relative;
  top: 170px;
  color: #5B5CB8;
}

.become-content {
  width: 50%;
  height: 500px;
  margin-top: 200px;

}

.become-button {
  margin: 120px auto 100px auto;
  border: none;
  background:
    #7D7EC7;
  padding: 10px 25px 10px 25px;
  font-family: 'Inter', sans-serif;
  font-style: normal;

  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #FFFFFF;

}

.accordion-item {
  margin-top: 20px;
  width: 80%;
  border-radius: 5px;

}

.accordion-body {

  background: #F0EFF7;
}

/* 
  https://studyqa.com/advisers */
.become-content1 {
  display: flex;
  width: 70%;

  height: auto;
  float: left;

  margin-top: 50px;
  text-align: left;

}

.become-content2 {
  display: flex;
  width: 70%;

  height: auto;
  float: right;
  margin-top: 70px;


  text-align: left;

}

.become-content3 {
  display: flex;
  width: 70%;

  height: auto;
  float: left;

  margin-top: 70px;
  text-align: left;

}

.become-content4 {
  display: flex;
  width: 70%;

  height: auto;
  float: right;
  margin-top: 70px;
  text-align: left;

}

.become-content-1-1 {
  width: 10%;
  background-image: url("../../images/gear.webp");
  background-position: center;
  background-size: cover;
  padding: 10px;
  margin: 10px;

}

.become-content-head {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 20px;
  line-height: 36px;

  color: #000000;
}

.become-content-head2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 12px;


  color: gray;
}

@media (max-width:1344px) {
  .become-content {

    width: 60%;
  }





}

@media (max-width:1024px) {
  .become-content {
    width: 70%;
  }

  .become-pic {
    display: none;
  }
}

@media (max-width:830px) {
  .become-content {
    width: 90%;
  }

}

@media (max-width:650px) {
  .become-content {
    width: 100%;

  }

  .become-content1,
  .become-content2,
  .become-content3,
  .become-content4 {
    float: none;
    width: 90%;
    margin: 50px auto;

  }

  .become-content-1-1 {
    width: 10%;
  }

  .become-button {
    margin-top: 20px;
  }

  .become-content-head {
    line-height: 20px;

  }

  .become-head {
    font-size: 40px;
  }

  .become-head2 {
    font-size: 15px;
  }


}

@media (max-width:500px) {
  .become-content-head {
    font-size: 15px;
  }

  .become-content-head2 {
    font-size: 10px;
  }

  .become-content-1-1 {
    width: 15%;
  }

  .become-button {
    width: 90%;
  }

  .button-div {
    width: 90%;
  }
}

@media (max-width:360px) {
  .button-div {
    margin-top: 100px;
  }

  .become-content-1-1 {
    width: 17%;
  }

}