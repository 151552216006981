* {
  align-items: center;
  /* justify-content: center;
  box-sizing: border-box;  */
}

.content-illus1 {
  position: relative;
  top: -10px;
  width: 620px;
}

.dropdown-menu {
  text-align: center;
}




.accordion-1-padding {
  padding-left: 20px;
}

.services-link {
  text-decoration: none;
  color: black;
}

.all-aplicable {
  font-family: 'Baloo Thambi 2';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  /* identical to box height */


  color: #00000060;
}

.required {
  color:
    #EA2020;
}

.seselo-display {
  display: none;
}

.search-abroad2 {
  width: 35%;
}

.why-kounselo2 {
  margin-bottom: 200px;
}

.kounselo-mobile {
  display: none;
}

.kounselo2 {
  position: fixed;
  top: -100px;
  width: 70%;
  margin: auto;
  z-index: 2;

  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  text-align: center;
}

/* footer */


td {
  border-top: 0.5px solid rgba(0, 0, 0, 0.392);
}

th {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 137.02%;
  /* identical to box height, or 36px */


  color: #838383;
}

.order {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 131.52%;
  /* identical to box height, or 66px */
  position: relative;
  left: -38%;
  top: 120px;
  text-transform: uppercase;

  color: #7D7EC7;

}

.order-main {

  background: linear-gradient(180deg, #E6E5FF 0%, rgba(244, 244, 255, 0) 134.58%);
}


.container5 {
  border-top: 2px solid rgba(0, 0, 0, 0.221);
  width: 100%;
  height: 600px;
  position: relative;

  bottom: 0px;

  margin-bottom: 0px;
  text-align: center;
  padding: 20px;
  background: #11112E;
}

.container6 {
  border-top: 2px solid rgba(0, 0, 0, 0.221);
  width: 100%;
  height: 600px;
  position: relative;
  top: 900px;
  bottom: 0;
  text-align: center;
  padding: 20px;
  background: #11112E;
}

.social-media {
  width: 6%;
  margin-left: 20px;
  position: relative;
  left: -5%;
  top: 100px;
}

.foot-para {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  /* or 49px */

  position: relative;
  top: 20px;
  color: #FFFFFF;
}

.footer2 {
  display: flex;
  width: 100%;
  height: 400px;
  text-align: left;
  justify-content: space-around;
  position: relative;

  left: 1%;
}

.about {
  flex-basis: 30%;
  position: relative;
  top: -30px;

}

.find-us-on {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20.6946px;
  line-height: 96.7%;
  /* identical to box height, or 31px */

  letter-spacing: -0.03em;
  position: relative;
  top: 90px;
  color: #A4A6E6;
}

.service {
  flex-basis: 15%;
  position: relative;
  top: px;
  margin-top: px;
}

.support {
  flex-basis: 10%;
  position: relative;
  top: -102px;
}

.foot-link {
  text-decoration: none;

}

.social {
  flex-basis: 10%;
  margin-top: px;
  position: relative;
  top: -102px
}

.kounselo-display {
  display: none;
}

.crypto {
  flex-basis: 15%;
  height: 300px;

}

.foot-head {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 96.7%;
  /* identical to box height, or 31px */

  letter-spacing: -0.03em;

  color: #A4A6E6;
}

.footer-brand {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-decoration: none;
  line-height: 77px;
  text-transform: uppercase;
  margin-left: 5px;
  position: relative;
  top: 3px;
  color: #FFFFFF;
}

.foot-content {
  font-family: 'Gotham Book ', sans-serif;
  font-style: normal;

  font-size: 15px;
  line-height: 25px;
  /* or 56px */

  letter-spacing: -0.03em;

  color: #FFFFFF;
}

.footer5 {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background-color: #000000;
}
.footer-link-main{
  text-align: left;
}
.copywrite {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.8091px;
  line-height: 96.7%;
  /* or 19px */
  position: relative;
  top: 130px;
  letter-spacing: -0.03em;

  color: #FFFFFF;

}



@media (max-width:1280px) {
  .service {
    top: -10px;
  }

  .support {
    top: -110px;
  }

  .social {
    top: -110px;
  }

  .crypto {
    top: -79px
  }

}

@media (max-width:1024px) {
  .support {
    top: -122px;
  }

  .social {
    top: -122px;
  }

  .crypto {
    top: -90px;
  }

}

@media (max-width:990px) {
  .footer2 {
    display: block;
    padding: px;
    text-align: center;

  }
  .footer-link-main{
    text-align: center;
    margin: auto;
    width: 100%;
    padding-right: 10px;
 
  }

  .copywrite {
    position: relative;
    top: 500px;
    font-size: 10px;
    margin: auto;
  }

  .container5 {
    width: 100%;
    text-align: center;
    padding: 20px;
    height: 1000px;
  }

  .service {
    margin-top: 140px;
  }

  .support {
    margin-top: 190px;

  }

  .social {
    margin-top: 300px;
  }

  .social-media {
    left: 0px;
  }

  .crypto {
    color: #000000;
    height: 300px;
    


  }

}
@media (max-width:320px) {
  .container5{
    height: 1100px;
  }
  .copywrite{
    top:600px
  }
  
}

.pc-nav {
  background-color: transparent;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 100;
}



.no-image {
  position: relative;
  left: 10%;
  width: 18%;

}


.content {
  display: flex;
  background: linear-gradient(91.96deg, rgba(191, 191, 226, 0.5) 20.99%, rgba(200, 196, 221, 0.48) 48.16%, rgba(225, 213, 209, 0.42) 84.97%, rgba(234, 219, 206, 0.4) 94.61%);
  opacity: 0.94;
  height: 900px;
  align-items: left;
  position: relative;
  top: px;
  text-align: left;




}

.content2 {
  display: none;
  background: linear-gradient(91.96deg, rgba(191, 191, 226, 0.5) 20.99%, rgba(200, 196, 221, 0.48) 48.16%, rgba(225, 213, 209, 0.42) 84.97%, rgba(234, 219, 206, 0.4) 94.61%);
  opacity: 0.94;
  height: auto;

  position: relative;
  top: px;

  width: 100%;
}

.search-display {
  position: relative;
  height: 600px;
  top: -150px;
  flex-basis: 50%;

  margin-left: px;
  padding-left: 135px;
}

.search-display2 {
  text-align: left;
  padding-left: 20px;
}

.illus-display {
  flex-basis: 50%;

}

.footer10 {
  position: relative;
  top: 900px;
}

.search-illus {
  position: relative;

  float: right;
  top: 0px;
  width: 800px;


}

.mobile-view {
  display: none;
}

.search-comp {
  position: relative;
  top: 00px;
  left: 37%;
  outline: none;
  border: none;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
}

.service-view-all {
  position: relative;
  top: 40px;
  font-size: 20px;
  color: #32326D;
  text-decoration: none;
}

.content-illus2 {
  position: relative;
  left: -70%;
  top: -100px
}

.content-illus3 {
  position: absolute;
  top: 490px;
  left: 50%
}

.why-kounselo {
  display: flex;
 
  height: 600px;
position: relative;
top: -200px;
  margin-bottom: 300px;
}

.why-kounselo-text {
  flex-basis: 50%;
  text-align: left;
  height: 800px;
  position: relative;
  top: 20px;
}

.why-kounselo-text2 {

  width: 90%;
  height: auto;

  text-align: justify;



}

.why-text {
  position: relative;
  left: 34%;
  top: 260px;
  font-family: 'Baloo Thambi 2', sans-serif;
  letter-spacing: 0em;
  text-align: left;
  font-style: normal;
  font-size: 50px;
  color: #000000;
}

.why-text3 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-size: 60px;
  letter-spacing: 0em;
  font-style: normal;
  color: #000000;
}

.why-text2 {
  position: relative;
  color: #303035e8;
  font-family: 'Baloo Thambi 2', sans-serif;
  top: 260px;
  left: 34%;
}

.why-text4 {

  color: #303035e8;
  font-family: 'Baloo Thambi 2', sans-serif;
  /* top: 200px; */
  left: 24%;

}

.buzz-main-mobile {
  margin-top: 0px;
  width: 100%;
  position: relative;
}

.why-illus {
  margin-top: 70px;
}

.why-kounselo-pic {
  flex-basis: 50%;
  height: 500px;

}

.content-text {
  position: absolute;
  top: 120px;
  font-size: 60px;
  left: 22%;
  color: #303035;
  font-family: 'Baloo Thambi 2', sans-serif;
  text-align: left;
  z-index: 2;
}

.content-text3 {

  top: 120px;
  font-size: 45px;
  color: #303035;
  font-family: 'Baloo Thambi 2', sans-serif;
  text-align: left;
}

.slider-hyper {
  text-decoration: none;
  color: #000000;
}

.content-text2 {
  position: absolute;
  top: 430px;
  left: 22%;
  font-family: 'Baloo Thambi 2', sans-serif;
  text-align: left;
  font-size: 25px;
  z-index: 2;
}

.content-text4 {

  top: 330px;

  font-family: 'Baloo Thambi 2', sans-serif;
  text-align: left;
  font-size: 12px;
}

.search {
  margin-top: 50px;
  top: 500px;
  background-color: white;
  background-image: url('magnify.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  background-size: 20px;
  /* left: 80px; */

  border: none;
  /* padding: 10px 40px 10px 10px;  */
  padding-left: 40px;
  outline: none;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

}

.search2 {
  position: relative;
  top: 370px;
  background-color: white;
  background-image: url('magnify.png');
  background-position: 10px 12px;
  background-repeat: no-repeat;
  background-size: 20px;
  /* left: 80px; */

  border: none;
  /* padding: 10px 40px 10px 10px;  */
  padding-left: 40px;
  outline: none;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;

}

.search::placeholder {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 96.7%;
  color: #393939;
}

.buzz-text {
  color: #303035e8;
  font-family: 'Baloo Thambi 2', sans-serif;
  position: relative;
  margin-bottom: 10px;
  margin-left: 150px;

}

.incube-head {
  font-family: 'Gotham', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 30.6182px;
  line-height: 38px;
  /* identical to box height */
  letter-spacing: 0.02em;
  color: #000000;

}

.buzz-heading {
  font-size: 40px;
  color: #303035;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  margin-left: 150px;
  /* position: relative;
  left: -30%;

  top: -200px; */
}

.buzz-component-main {

  position: relative;
  top: -230px;
  width: 100%;
  text-align: left;
}

.buzz-component {
  height: auto;
  width: 100%;
  background: #F0EFF7;
  position: relative;
  top: px;
  margin: auto;
  padding: 20px;
  align-items: center;
  justify-content: center;

}

.name {
  background-image: url('name2.png');
  background-position: 15px 4px;
  background-repeat: no-repeat;
  background-size: 5%;
}

.email {
  background-image: url('mail.png');
  background-position: 15px 4px;
  background-repeat: no-repeat;
  background-size: 5%;
}

.tel {
  background-image: url('phone.png');
  background-position: 15px 4px;
  background-repeat: no-repeat;
  background-size: 5%;
}
.name3 {
  background-image: url('name2.png');
  background-position: 15px 8px;
  background-repeat: no-repeat;
  background-size: 20px;
}

.email3 {
  background-image: url('mail.png');
  background-position: 15px 8px;
  background-repeat: no-repeat;
  background-size: 20px;
}

.tel3 {
  background-image: url('phone.png');
  background-position: 15px 8px;
  background-repeat: no-repeat;
  background-size: 20px;
}
.buzz-cards {
  width: 25%;
  box-sizing: border-box;
  height: auto;
  background: white;
  border-radius: 22px;
  position: relative;
  text-align: justify;
  margin: 50px;
  padding: 20px;
  padding-bottom: 50px;
}

.buzz-card-text {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}

.buzz-line {
  position: relative;
  top: 75px;
}

.buzz-name {
  position: relative;
  top: 60px;
  left: 15%;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.buzz-university {
  position: relative;
  top: 55px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: -0.03em;
  color: rgba(0, 0, 0, 0.49);
  left: 15%;
}

.faq-component {
  width: 100%;
  height: 600px;
  margin: auto;
  position: relative;
  top: -100px;
  text-align: center;
}

.how-it-works {
  position: relative;
  left: -30%;
}
.hired-head{
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  position: relative;
  font-weight: bolder;
}
.faq-head {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  position: relative;
  font-weight: bolder;
  bottom: px;
  font-size: 25px;
  /* font-weight: ; */
}

.faq-head2 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  position: relative;
  font-weight: 600;
  bottom: px;
  font-size: 15px;
  margin-bottom: 10px;
}

.faq-mid {
  width: 70%;
  height: 80px;
  background: #F0EFF7;
  border-radius: 10px;
  position: relative;

  top: 7px;
  text-align: left;
  padding: 10px;
}

.faq-select {
  padding: 20px;
  width: 70%;
  outline: none;
  border-color: rgba(128, 128, 128, 0.192);
  margin-top: 10px;
  border-radius: 10px;
  position: relative;
  top: 10px;
}

.incube-pic {
  width: 60%;
  top: 100px;
  margin-left: 30px;

}
.incube-pic3 {
  width:100px;
 


  
}
.hired-main{
  display:flex;
   width:760px;
   margin:auto;
   flex-wrap:wrap
}
.incube-pic4 {
  width: 60%;

 

}
.incube-pic2 {
  margin-right: 30px;
  top: 100px;
  width: 60%;

}

.incube-component {
  width: 80%;
  height: auto;
  margin: auto;
  position: relative;

  border-radius: 59px;
  top: 0px;
  text-align: center;
  padding: 5PX;
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 700;
  padding-top: 20px;
}

.form {
  width: 100%;
  background-color: black;
  height: 80px;
  position: relative;
  top: 400px;
}

.conslo-lead-form {

  /* position: relative;left: 100px; */
  font-family: 'Inter', sans-serif;
  font-style: normal;
  position: relative;


}

.lead-form {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.788);
  position: relative;
  top: 15px;
  font-size: 30px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background-color: black;
  border: none;
}

.lead-form:hover {
  color: white;
}

.dropdown2 {
  display: inline;
  position: relative;
  left: 800px;
  top: 10px;

  color: rgba(255, 255, 255, 0.788);
  opacity: 100;
  size: 50;
}

.personal-plus {
  width: 30px;
  position: relative;
  top: -30px;

  left: 59px;
}

::-webkit-scrollbar-thumb {
  background: #B2B2EA;
  width: 10px;
  height: 60px;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 15px;

}

::-webkit-scrollbar-track {
  background-color: #e9e9f536;

}

.btn-1 {
  position: relative;
  top: 70px;
  background-color: black;
  color: #F5F5F5;
  left: 40px;
  font-style: normal;
  font-family: 'Inter', sans-serif;

  border-radius: 3px;

}

.btn-2 {
  position: relative;
  top: 120px;
  left: 40px;
  background-color: black;
  color: #F5F5F5;
  border-radius: 3px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.btn-1:hover {
  color: white;
}

.btn-2:hover {
  color: white;
}

.trusted-by {
  position: relative;
  top: 200px;
  width: 95%;
  height: 70px;

  background-color: black;
}

.explore {
  position: relative;
  left: 50%;
  margin: 30px;
}


.trusted-by-link {
  text-decoration: none;
  color: #F5F5F5;
  position: relative;
  top: 20px;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  left: 50px;
  font-size: 20px;
}

.trusted-by-link:hover {
  color: #F5F5F5;
}


.link-2 {
  text-decoration: none;
  color: black;
}

.mobile {
  display: none;
}

/* kounselo form */
.blur {
  opacity: 30%;
}

.body-color {
  background-color: rgba(0, 0, 0, 0.445);

}

.accordion-item {
  text-align: left;
}

.accordion-title {
  width: 95%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  height: 77px;
  background-color: white;

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17.6946px;
  line-height: 96.7%;
  /* identical to box height, or 31px */


  color: #000000;
  border: 1.81324px solid #D7D7DD;
  border-radius: 20px;
}

.accordion-content {
  height: auto;
  width: 95%;
  margin-top: 5px;
  background: #F0EFF7;
  border-radius: 20.5609px;
  text-align: left;
  padding: 20px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.6946px;
  line-height: 126.7%;
  /* or 40px */


  color: #000000;
}

.drop-acc {
  transform: rotate(180deg);

}

.kounselo {
  position: relative;
  width: 100%;
  height: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  text-align: center;
}

.incube-content {
  display: flex;
  justify-content: space-between;
}

.box-shadow {
  box-shadow: 0px 3.93247px 17.6961px rgba(0, 0, 0, 0.25);
}

.kounselo-form-display5 {
  width: 80%;
  height: 400px;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  margin: auto;
}

.kuonselo-from-heading {
  position: relative;
  left: 60%;
  color: rgba(0, 0, 0, 0.575);
  font-size: 20px;
  top: 170px;
}

.search-illus2 {
  margin-top: 230px;
  width: 80%;
}

.span1 {
  font-size: 26px;
  font-style: normal;
  font-family: "Gotham", sans-serif;
  color: black;
  font-weight: 700;
}

.span2 {
  font-size: 15px;
  font-style: normal;
  font-family: "Gotham", sans-serif;
  color: #656565;
}

.kounselo-form::placeholder {
  font-style: "'Inter' , sans-serif";
}

.submit {
  position: relative;
  left: 2%;
  top: 10px;

  float: left;
  margin-left: 20px;
  margin-top: 10px;
  width: 20%;
  font-family: 'Inter', sans-serif;
  background: #7D7EC7;
  border: 1px solid #7B7B7B;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 20px 5px 20px;
  font-weight: bolder;
  color: white;

}

.kounselo-form {
  width: 90%;
  position: relative;
  margin: 10px;

  /* background-image: url('name.png');
  background-position:80px 12px;
  background-repeat: no-repeat;
  background-size:10px; */

  border: 1px solid #7b7b7b2b;
  border-radius: 10px;
  outline: none;

  padding-left: 50px;
  padding-top: 5px;

  padding-bottom: 5px;
  border-radius: 5px;



}

.kounselo-form::placeholder {
  font-family: "'Inter' , sans-serif";
}
.pricing{
  
    background-image: url('rupee.webp');
    background-position: 15px 15px;
    background-repeat: no-repeat;
    background-size: 10px;
  
  
}

.kounselo-form-display {
  display: flex;
  width: 80%;
  height: 400px;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  margin: auto;

}

.e-book {
  display: flex;
}

.kounselo-form-display1 {
  flex-basis: 40%;
}

.kounselo-form-display2 {
  flex-basis: 50%;
}

.icon {
  position: absolute;
  left: 43%;
  top: 300px;
}

.icon2 {
  position: absolute;
  left: 43%;
  top: 355px;
}

.icon3 {
  position: absolute;
  left: 43%;
  top: 250px;
}

.icon4 {
  position: absolute;
  left: 64%;
  top: 373px;
}

.icon5 {
  position: absolute;

  left: 64%;
  top: 425px;
}

.display2 {
  display: none;
}

.cross {
  background: none;
  border: none;
  position: absolute;
  color: white;
  position: relative;
  top: -420px;
  left: 5%;
  font-size: 20px;
}

#signup-pop-up:hover {
  background-color: white;
  color: red;
}

.kounselo-form::placeholder {
  position: relative;
}

.container4 {
  position: relative;
  top: 800px;
  background-color: white;
  border-top: 2px solid rgba(0, 0, 0, 0.221);
  width: 100%;
  height: 600px;



  text-align: center;
  padding: 20px;
  background: #11112E;
}

.container2 {

  position: relative;
  top: 300px;
  width: 100%;
  background: #11112E;
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 700;

  color: #A5A6E7;

}

.faq-select2 {
  position: relative;
  left: -2%
}

.nav-item {
  color: #A5A6E7;
}


.search-abroad {
  position: absolute;
  left: 30%;
  width: 15%;
  top: 220px
}

.star-illus {
  position: absolute;
  left: 73%;
  top: 100px
}

.star-illus2 {
  position: absolute;
  left: 35%;
  top: 560px;
  width: 10%;
}

.sellerDashcard {
  display: flex;
  margin: auto;
  position: relative;
  top: 300px;
  flex-wrap: wrap;

}

.dropDown {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height */


  color: #7D7EC7;
}

.dropDown:hover {
  background: rgba(112, 117, 196, 0.49);
  color: white
}



.brand-pic {
  position: relative;
  left: 90%
}

.navbar-brand {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 77px;
  /* identical to box height */
  position: relative;
  left: 3%;

  color: #303035;
}

@media (max-width:1475px) {

  .search-abroad {
    left: 33%;
  }

  .star-illus {
    top: 100px;
  }

  /* .search-illus {
    width: 90%;
    top: -60px;
  } */
.content-text{
  font-size: 50px;
}
  .buzz-cards {
    margin: 10px;
  }

  .content-illus1 {

    top: 10px;
  }



}
.ilts-cross{
  float: left;
  background: transparent;
  border: none;
  margin-left: 10px;
  
  color:black;
 
}
.kounselo2-main {
  position: absolute;
  z-index: 99;
  left: 0px;
 
  width: 100%
}
@media (max-width:1280px) {
  .content-text{
    font-size: 40px
  };
  
}

@media (max-width:1050px) {

  .content-text {
    font-size: 45px;
    top: 150px;
  }

  .search-display {
    padding-left: 105px;
  }

  .search-illus {
    width: 100%;
  }

  .star-illus {
    top: 130px;
  }

  .content-illus1 {
    top: 80px;
    width: 520px;

  }

  .buzz-cards {
    width: 30%;
  }

}

@media (max-width:991px) {
  .content2 {

    height: 1000px;
  }
.kounselo-form-display5{
  margin-top: 900px;
  z-index: 98;
}
.kounselo2{
  z-index: 98;
}
  .content {
    height: 700px;
  }

  .search-display {
    top: -50px;
  }

  .content-text2 {
    top: 310px;
  }

  .content-text3 {
    font-size: 60px;
  }

  .content-text4 {
    top: 350px;
    font-size: 25px;
  }

  .search-illus2 {
    top: 500px;
  }

  .why-text {
    left: 15%;
    font-size: 35px;
  }

  .why-text2 {
    left: 15%;
  }

  .search-abroad {
    left: 36%;
    top: 240px;

  }

  .content-text {
    font-size: 60px;
  }

  .search-display {
    padding-left: 80px;


  }

  .content-illus1 {
    position: relative;

    width: 100%;

  }

  .buzz-heading {
    left: 0px;
  }

  .buzz-cards {
    width: 40%;
    margin: 20px;
    height: auto;

  }

  .why-kounselo {
    top: -200px;
    margin-bottom: 100px;
  }


  .faq-head {
    left: 0PX;

  }

  .faq-head2 {
    left: 0px;
  }


  .content-text {
    font-size: 35px;
  }

  .submit {
    left: 0px;

    width: 50%;
  }

  .search-illus {
    width: 100%;
    float: none;

  }

  .search2 {
    top: 430px;
    margin: auto;
    left: 0px;
  }


  .buzz-heading {
    left: 0px;
  }

  .e-book {
    display: block;
    height: auto;
  }

  .kounselo-form-display5 {
    height: auto;
    padding: 20px;

  }

  .submit {
    margin: auto;
    float: none;
  }

  .kounselo2-main {
    width: 92%;
  }

}

@media (max-width:601px) {
  .why-kounselo {
    display: block;
  }

  .e-book {
    display: block;
    height: auto;
  }

  .accordion-title {
    width: 100%;
  }

  .accordion-content {
    width: 100%;
  }

  .incube-head {
    font-size: 20px;
  }

  .incube-pic2 {
    margin: 20px auto;
  }

  .kounselo-form-display5 {
    width: 100%;
    margin: 990px auto;
  }
  .kounselo2-main{
    width: 100%;
  }

  .incube-pic {
    margin: 10px auto;
  }

  .incube-content {
    display: block;
  }

  .mobile-view {
    display: block;
  }

  .home-pc {
    display: none;
  }

  .search2 {
    top: 200px;
    margin-left: 40px;
  }

  .incube-component {
    position: relative;
    top: 20px;

  }

  .submit {
    float: none;
    left: 0px;
    margin-left: 0px;
  }

  .kounselo {
    display: none;
  }

  .kounselo-display {
    display: block;
  }

  .content {
    display: none;
  }
  .kounselo2{
    width: 100%;
    margin: auto;
  }
  .content2 {
    display: block;
    height: 1200px;


  }

  .star-illus2 {
    top: 570px;
  }


  .content-illus1 {
    top: -40px;
  }

  .buzz-component-main {


    position: relative;
    top: 200px;
    text-align: center;
  }

  .buzz-heading {
    float: none;
    margin: 0px;
  }

  .content-text4 {
    font-size: 22px;
    top: 430px;
    text-align: center;
  }

  .kounselo-mobile {
    display: block;
    top: -100px;
  }

  .kounselo-pc {
    display: none;

  }

  .content-text {
    font-size: 70px;
    left: 0px;
    margin-left: 20px;
  }

  .content-text2 {

    left: 0px;
    margin-left: 20px;
  }

  .search-abroad {
    top: 340px;
    width: 35%;
  }

  .search-illus {
    top: 50px;
  }

  .star-illus {
    top: 740px;
    left: 35%;
  }

  .content {
    height: 1500px;
  }

  .why-text {
    font-size: 65px;
    left: 5%;
  }

  .why-text2 {
    left: 5%;
    font-size: 23px;
  }

  .buzz-component {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    background: none;

  }


  .kounselo-form-display {
    display: block;

  }


  .search-illus {
    width: 90%;
    left: 1%;
  }

  .buzz-text {
    float: none;
    margin: 0px;

  }

  .incube-component {
    margin: auto;
  }

  .search-abroad {

    left: 64%;
  }

  .accordion-title {
    padding: 10px;
    font-weight: 100;
  }

  .faq-component {
    top: 0px;
    width: 100%;
  }

  .star-illus {
    width: 13%;
  }

  .accordion-item {
    text-align: center;
  }

  .buzz-cards {
    width: 70%;
    padding: 20px;
    box-shadow: 0px 10px 14px 4px rgba(0, 0, 0, 0.25);
    margin: 30px auto;
  }

  .faq-head2 {
    margin-bottom: 20px;
  }
}

@media (max-width:500px) {

  .faq-component {

    width: 100%;
  }

  .name,
  .tel,
  .email {
    background-position: 17px;
  }

  .buzz-heading {
    font-size: 30px;
  }

  .content-text3 {
    font-size: 40px;
  }

  .content-text4 {
    position: relative;
    font-size: 17px;
    top: -40px;
  }

  .search-illus2 {
    width: 100%;
  }

  .content-text2 {
    font-size: 20px;
  }

  .why-text {
    font-size: 45px;
  }

  .why-text2 {
    font-size: 15px;
  }

  .buzz-component {
    margin: auto;
    margin-bottom: 00px;
  }

  .search-illus {
    width: 100%;
  }

  .content2 {
    height: auto;
  }

  .why-text3 {
    font-size: 40px;
  }

  .why-illus {
    margin-top: 40px;
  }

  .content-illus1 {
    width: 100%;
    top: -100px;
  }

  .why-kounselo-text2 {
    width: 90%;
    text-align: center;
  }

  .buzz-cards {
    width: 90%;
  }

  .star-illus2 {
    top: 470px;
  }

}

.col-md-10 {
  position: relative;
  top: -10px;

}

@media (max-width:360px) {
  .content-text3 {
    font-size: 25px;
  }

  .search2 {
    margin-left: 0px;
    width: 90%;
  }

  .star-illus2 {
    top: 400px;
  }

  .content2 {
    height: 950px;
  }
 
}


/* how it works */

.opt {
  background-color: #F0EFF7;
}

.opt:hover {
  background-color: #F0EFF7;
}

/* forms */

.heading {
  position: relative;
  left: 5%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */


  color: #858585;
  /* background-color: black; */


  color: black;
}

.profile {
  /* padding: 20px; */
  text-align: left;

  position: relative;
  top: 100px;



}

.profile-forms {

  display: inline;

}

.profile-save {
  position: relative;
  left: 42%;
  margin-bottom: 20px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */
  width: 7%;


  color: #FFFFFF;

  background: #7D7EC7;
  border-radius: 7.42317px;
  padding: 5px 10px 5px 10px;
}

.profile-save2 {
  margin-left: 150px;
  margin-right: 5px;
  margin-bottom: 20px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */
  width: 100px;
  color: #FFFFFF;
  background: #7D7EC7;
  border-radius: 7.42317px;
  padding: 5px 10px 5px 10px;
}

.profile-delete-dash{
  margin-left: 120px;
  margin-right: 25px;
  margin-bottom: 20px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 133.02%;
  width: 166px;
  color: #FFFFFF;
  background: #7D7EC7;
  border-radius: 7.42317px;
  padding: 5px 10px 5px 10px;
}

.button-upload {
  margin: auto;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background: #7D7EC7;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
  font-size: 12px;
  line-height: 133.02%;
  color: white;
  border-radius: 2px;
}

.photo-cancel {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  background: white;
  border: 2px solid #7D7EC7;
  font-weight: 500;
  padding: 5px 20px 5px 20px;
  font-size: 12px;
  line-height: 133.02%;
  color: #7D7EC7;
  border-radius: 2px;
  margin-left: 10px;
}

.profile-cancel {
  position: relative;
  left: 43%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */


  color: #FFFFFF;
  color: #000000;
  width: 7%;
  border: 0.742317px solid #B5B5B5;
  border-radius: 7.42317px;
  padding: 5px 10px 5px 10px;
}

.profile-cancel2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 27px */


  color: #FFFFFF;
  color: #000000;
  width: 100px;
  border: 0.742317px solid #B5B5B5;
  border-radius: 7.42317px;
  padding: 5px 10px 5px 10px;
}

.col-md-6 {
  position: relative;
  top: -10px;
}

#file {

  height: 200px;

  border: 2px solid rgba(0, 0, 0, 0.179);
  padding-top: 70px;
  padding-left: 50px;


  color: #11112E;

}

.creation-profile1 {
  margin-top: 80px;
  width: 58%;
  margin-left: 30%;
  height: auto;
}

.creation-profile2 {
  width: 78%;
  margin: 100px auto;
  height: auto;
}

.education {
  display: flex;
  height: auto;

}

.label-flex {
  flex-basis: 20%;
  margin-top: 20px;
}

.label-flex2 {
  flex-basis: 10%;
  margin-top: 20px;
}

.profile-step2 {
  margin-top: 100px;
}

.label-flex3 {
  flex-basis: 12%;
  margin-top: 20px;
}

.label-flex4 {
  flex-basis: 27%;
  margin-top: 20px;
}

.label-flex5 {
  flex-basis: 16%;
  margin-top: 20px;
}

.profile-input {
  flex-basis: 80%;
  margin-top: 20px;
}

.profile-input2 {
  flex-basis: 40%;
  margin-top: 20px;
}

.profile-input3 {
  flex-basis: 30%;
  margin-top: 20px;
}

.profile-input4 {
  flex-basis: 35%;
  margin-top: 20px;
}

.profile-input5 {
  flex-basis: 35%;
  margin-top: 20px;
}

.heading2 {
  position: absolute;
  top: 20px;
  left: 140px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20.2088px;
  line-height: 133.02%;
  /* or 16px */


  color: #000000;

}

.verify {
  background: #7D7EC7;
  border-radius: 7.42317px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  padding: 5px 14px 5px 14px;
  font-size: 15px;
  line-height: 133.02%;
  /* identical to box height, or 27px */


  color: #FFFFFF;
}

.profile-info {

  padding: 10px 20px 10px 20px;

  border: 1.10632px solid #B5B5B5;
  border-radius: 5px;
  width: 85%;
  /* border-color:rgba(255, 0, 0, 0.445); */
  outline: none;
}



.hr1 {
  transform: rotate(90deg);
  width: 45%;
  position: absolute;
  top: 670px;
  left: -16.7%;
}

.hr2 {
  transform: rotate(90deg);
  width: 15%;
  position: absolute;
  top: 1190px;
  left: -1.6%;
}

.types2 {

  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 38px;
  /* identical to box height */


  color: #000000;

}

.types1 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  /* identical to box height */


  color: #000000;
}

.radio {
  width: 35px;
  height: 35px;
}

.check-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12.85141px;
  margin-left: 5px;
  /* or 9px */


  color: #7D7EC7;

}

.check-box2 {
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

.check-flex {
  display: flex;
  width: 90%;
  margin: 30px auto;
}

.profile-info6 {

  left: 28%;
  padding: 10px 20px 10px 20px;

  width: 100%;
  border: 1.10632px solid #B5B5B5;
  border-radius: 10.1264px;
  /* border-color:rgba(255, 0, 0, 0.445); */
  outline: none;
}

.profile-info2 {

  display: inline-block;
  padding: 10px 20px 10px 20px;

  border: 1.10632px solid #B5B5B5;
  width: 100%;
  left: 28%;
  /* border-color:rgba(255, 0, 0, 0.445); */
  outline: none;
}

.form-flex {
  display: flex;
  width: 100%;

}

.form-flex2 {
  display: flex;
  width: 100%;

}

.profile-info3 {

  display: inline-block;
  padding-left: 19px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 50px;
  /* identical to box height */


  color: #767676;

  left: 30%;
  border: 1.10632px solid #B5B5B5;
  /* border-color: rgba(255, 0, 0, 0.445); */
  outline: none;
  /* box-sizing: border-box; */
  width: 50%;
  height: 40px;
  background: #FFFFFF;
}

#description {

  left: 20%;
  height: 200px;
  top: -40px;
}

/* .check-box {} */
.check-box {

  margin-top: 20px;
  margin-bottom: 20px;
  left: 28.8%;
  top: px;
  height: 40px;
  width: 40px;
}

.button-flex {
  display: flex;
  justify-content: space-between;

  margin-top: 20px;
}

.button-save-flex {
  display: flex;

  align-items: end;

}

.button-cancel-flex {
  flex-basis: 50%;

}

.button-cancel {

  left: 10px;
  top: 70px;
  margin: 5px;
  color: #5D66B5;
  border: none;
  background: none;
}

.label2 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  margin-top: 10px;

  /* identical to box height */
  /* margin: 20px; */

  color: #000000;
  flex-basis: 36%;

}

.label3 {
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  margin-top: 10px;

  /* identical to box height */
  /* margin: 20px; */

  color: #000000;
  flex-basis: 36%;

}

.form-input {
  flex-basis: 100%
}

.heading3 {
  background: #BDC0E1;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 15px 15px 0px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  padding: 10px;
  line-height: 96px;
  /* identical to box height */


  color: #000000;

}

.profile-label {



  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  /* identical to box height, or 27px */


  color: #858585;

}

.profile-pic {
  position: relative;
  border-radius: 50%;
  left: 25px;
  top: -15px;
}


.save {
  background-color: black;
  color: white;
  padding: 5px 50px 5px 50px;

  border: 5px solid black;
  margin: 20px;
  border-radius: 10px;
  position: relative;
  top: 20px;



}



.personal-heading {
  position: absolute;
  left: 5%;
  top: 307px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 40px */


  color: #000000;

}

.personal-heading4 {

  display: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 40px */


  color: #000000;

}

.personal-heading2 {
  position: absolute;
  left: 5%;
  top: 1050px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 40px */


  color: #000000;

}

.personal-heading3 {
  position: absolute;
  left: 5%;
  top: 1340px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.02%;
  /* identical to box height, or 40px */


  color: #000000;
}

.profile-pic-head {

  background: linear-gradient(90.53deg, #6E73FF 5.43%, #9766FF 94.47%);
  border-radius: 50px 0px 0px 0px;
  width: 58%;
  position: relative;
  left: 30%;
  height: 100px;
}

.profile-pic-head2 {

  display: flex;
  width: 50%;
  position: relative;
  left: 30%;
}

.profile-pic-head3 {
  flex-basis: 80%;
}

.heading-count {
  background-color: #000000;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 50%;
}

.heading-count2 {
  background-color: #000000;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 50%;
}

.pic-save-button {
  border: none;

  background: #7D7EC7;
  border-radius: 7.42317px;
  color: white;
  padding: 5px;

}



/* dasboard */




.App {
  text-align: center;



}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* 
color: #858585;

/* service input forms */
.button-mobile-forms {
  display: none;
}

.mainheading {

  display: inline;
  width: auto;
  position: relative;
  top: 60px;
  height: auto;
  background: #BDC0E1;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 0px 15px 15px 0px;


  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;



  top: 60px;
  color: #000000;
  padding: 10px 20px 15px 20px;

}

.head-forms {
  width: auto;

}

/* .createService {} */

.resume {
  width: 400px;
  font-size: 36px;
}

.visa {
  width: 80%;
  padding: 1px 20px 10px 20px;
}

.compAppSupport {
  width: fit-content;
  vertical-align: middle;
  padding: 1px 20px 10px 20px;
}


/* pageContainer */
.pageContainer {
  width: 90%;
  height: auto;

  margin: 120px auto;
}

/* miniheading */
.miniheading {
  position: relative;

  top: 4px;

  font-size: 30px;
  font-weight: bold;
  color: #7d7ec7;
}
.miniheading2 {
  position: relative;

  top: 4px;

  font-size: 15px;
  font-weight: bold;
  color: #7d7ec7;
}


/* line below create your gig */
.line {

  position: relative;
  width: 100%;
  height: 0px;
  top: 2px;
  border: 1px solid #737582;
  transform: rotate(180deg);
}

.line2 {
  position: relative;
  width: 100%;
  height: 0px;
  top: 2px;
  border: 1px solid #737582;
  transform: rotate(180deg);
  margin-top: 25px;
  margin-bottom: 50px;
}



.button-save {

  left: 69%;
  border: 1px solid #5D66B5;
  margin-right: 0px;
  color: #5D66B5;
  padding: 5px 10px 5px 10px;
  background-color: white;
  font-weight: 900;
  width: 100%;

  height: 40px;
  right: 10px;
  top: 70px;
}

.button-savepublish {


  border: none;
  margin-left: 5px;
  color: white;
  font-weight: 900;
  padding: 5px 10px 5px 10px;
  background-color: #5D66B5;
  width: 100%;
  height: 40px;
  top: 70px;
  left: 35%;

}

@media (max-width:1280px) {
  .creation-profile1 {
    width: 60%;
  }
}

@media (max-width:990px) {
  .creation-profile2 {
    margin-top: 150px;
  }

}

@media (max-width:600px) {
 .hired-head{
  font-size: 20px;
 }
  .form-flex,
  .education {
    display: block;

  }
  

  .privacy-head {
    position: relative;
    top: 100px;
  }

  .personal-heading4 {
    display: block;
  }

  .heading-count,
  .personal-heading,
  .hr1,
  .hr2,
  .personal-heading2,
  .personal-heading3 {
    display: none;
  }

  .profile-pic-head {
    left: 0px;
    margin-left: 30px;
    width: 90%;
  }

  .personal-plus {
        left: -50px;
  }

  .profile-save {
    display: block;
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
    left: 0px;
  }

  .profile-cancel {
    display: block;
    left: 0px;
    margin: 10px auto;
    width: 60%;
  }

  .profile-pic {
    left: -35%;
  }

  .profile-pic-head3 {
    left: 0px;
  }

  .creation-profile1 {
    margin: auto;
    width: 90%;
  }

  .label3 {
    flex-basis: 300%;
  }

  .button-flex {
    display: block;
    margin: 20px auto;
    display: none;
  }

  .button-mobile-forms {
    display: block;
    margin-top: 50px;
  }

  .line2 {
    display: none;
  }

  .button-save-flex {
    display: block;
  }

  .button-savepublish {

    margin-left: 0px;
    margin-top: 10px;
  }

  .button-save {
    margin: 0px;
    margin-top: 10px;
  }

  .button-cancel {
    width: 100%;
    margin-top: 10px;
  }

  .profile-info3{
    width: 90%;
  }
}

@media (max-width:450px) {

  .personal-plus {
   left: -13px;
  }
  .heading2{
    left: 80px;

  }
  #file{
    width: 90%;
  }

}

.upload-div {
  background-color: white;
  width: 200px;
  margin: auto;
  padding: 5px;

}

.upload-div2 {
  background-color: white;
  width: 200px;
  position: absolute;
  border: 2px solid hsl(239, 40%, 64%);
  margin: auto;
  padding: 5px;

}

.marginLeft {
  margin-left: 5px;
}

.upload-div4 {
  background-color: white;
  width: 200px;
  margin: auto;
  position: absolute;
  padding: 5px;

}
.upload-divss {
  background-color: white;
  width: 200px;
  margin: auto;
  position: relative;
  top: -30px;
  padding: 5px;

}
.getOtpdiv {
  text-align: right;
}

.getOtp {
  border: none;
  background: transparent;
  font-family: 'Inter';

font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 136.02%;
/* or 20px */
float: right;
text-decoration-line: underline;
text-align: right;
color: #787878;
margin-right: 27px;
}
.submit3{
  width: 170px;
}
.getOtp2{

  border: none;
  background: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 136.02%;
  /* or 20px */
  float: right;
  text-decoration-line: underline;
  text-align: right;
  color: #787878;
  margin-right: 15px;
}


.overView-flex{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.upload-div-flex {
  display: flex;
  margin: auto;
  width: 100%;
}

.btn-warning {
  position: relative;
  padding: 5px 20px 5px 20px;

  line-height: 1.5;
  border-radius: 3px;
  color: black;
  background: transparent;

  transition: 0.2s;
  border: 1px solid hsl(239, 40%, 64%);
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 133.02%;
  /* identical to box height, or 27px */

  margin: auto;
  color: #000000;

}

.photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #DEDAF9;
  margin: 10px auto;
}

.btn-warning input[type="file"] {
  cursor: pointer;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: scale(3);
  opacity: 0;
}

.btn-warning:hover {
  background-color: white;
}
.bold{
  font-weight: bolder;
}

