.chng {
    color: rgba(91, 92, 184, 1);
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    font-style: italic;
}

.terms {
    text-align: left;
}

.heads {
    text-decoration: rgba(91, 92, 184, 1) solid underline;
    color: rgba(91, 92, 184, 1);
    font-family: 'Inter', sans-serif;
    margin-bottom: 25px;
}



.cookie{
    background: linear-gradient(180deg, #E6E5FF 0%, rgba(244, 244, 255, 0) 100%);
    text-transform: uppercase;
    color: rgb(91, 92, 184);
    letter-spacing: 2.5px;
    text-align: center;
    padding: 100px 100px 30px 100px;
    font-family: 'Gothic A1', sans-serif;
    font-size: 60px;
    margin-bottom: 10px;
}

.innertext {
    font-family: 'Gothic A1', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

}


.outline {
    margin: 90px;
    text-align: left;
}


hr {
    border: none;
    border-top: 1px solid black;
    width: 60%;
}

body {
    margin: 0;
}

.accept {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.onel,
#box {
    color: rgb(91, 92, 184);
    font-size: 18px;

}

.check {
    width: 20px;
    height: 20px;
}

.iaccept {
    background-color: rgb(91, 92, 184);
    color: #fff;
    padding: 0px 80px 0px 80px;
    border-radius: 10px;
    border: none;
}

@media (max-width: 320px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 0px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .onel,
    #box {
        font-size: 10px;
    }

    .iaccept {
        padding: 1px 10px 1px 10px;
        font-size: 8px;
        border-radius: 5px;
    }

}

@media (min-width: 375px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 20px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .onel,
    #box {
        font-size: 10px;
    }

    .iaccept {
        padding: 2px 10px 2px 10px;
        font-size: 10px;
        border-radius: 5px;
    }


}

@media (min-width: 425px) {
    .cookie {
        font-size: 25px;
        padding: 50px 0px 0px 0px;
        margin: 0;
        letter-spacing: 2px;
    }

    .outline {
        margin: 38px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .innertext {
        font-size: 12px;
    }

    .chng {
        font-size: 12px;
    }

    .heads {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .onel,
    #box {
        font-size: 12px;
    }

    .iaccept {
        padding: 2px 15px 2px 15px;
        font-size: 10px;
        border-radius: 5px;
    }

}

@media (min-width: 768px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 45px;
    }

    .pr .outline {
        margin: 70px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 16px;
    }

    .chng {
        font-weight: 700;
        font-size: 16px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 18px;
    }

    .onel,
    #box {
        font-size: 15px;
    }

    .iaccept {
        padding: 2px 48px 2px 48px;
        font-size: 15px;
        border-radius: 5px;
    }

}

@media (min-width: 1024px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 100px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 19px;
    }

    .chng {
        font-weight: 700;
        font-size: 19px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 22px;
    }

    .onel,
    #box {
        font-size: 20px;
    }

    .iaccept {
        padding: 2px 80px 2px 80px;
        font-size: 20px;
        border-radius: 5px;
    }
}

@media (min-width: 1440px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 120px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 20px;
    }

    .chng {
        font-weight: 700;
        font-size: 20px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 25px;
    }

    .onel,
    #box {
        font-size: 25px;
    }

    .iaccept {
        padding: 3px 100px 3px 100px;
        font-size: 25px;
        border-radius: 5px;
    }
}


.time-line-main {
    display: flex;
    margin-bottom: 150px;
}

.time-line-content1 {
    flex-basis: 65%;
    background-color: #F0EEFE;
    height: auto;
    border-radius: 5px;
    position: relative;
    top: 100px;
    margin-left: 30px;
    padding: 10px;
}

.timeline-time {
    font-family: 'Inter',  Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    /* identical to box height */


    color: #000000;
    position: absolute;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 10px;
    left: -3px;
    top: 50px;
}

.time-line-content {
    width: 90%;
    margin-left: 30px;
    border: 2px solid beige;
    height: auto;
    margin-top: 120px;
    text-align: left;
}

.time-line-content2 {
    margin-top: 70px;
}

.time-pic {
    background-color: #7D7EC7;

    border-radius: 50%;
    padding: 10px;
    width: 50px;
}

.time-pic2 {
    background-color: #7D7EC7;

    border-radius: 50%;
    padding: 10px;
    width: 50px;
}

.time-pic3 {
    background-color: #7D7EC7;

    border-radius: 50%;
    padding: 20px 12px 20px 12px;
    width: 50px;
}

.time-data {
    font-family: 'Inter',  sans-serif;
    font-style: normal;

    font-size: 16.6px;
    line-height: 31px;

    color: #000000;
    margin-left: 30px;
}

.time-data2 {
    width: 300px;
    width: 75%;
    background-color: white;
    height: 130PX;
    margin-top: 20px;
    padding: 20px;
}

.time-data4 {
    width: 300px;
    width: 75%;
    background-color: white;
    height: auto;
    margin-top: 20px;
    padding: 25px;
}

.time-pic-main {
    height: 220px;
}

.time-pic-main3 {
    height: 180px;
}

.time-pic-main4 {
    height: 480px;
}

.time-box-data {
    font-family: 'Inter', Sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    /* or 26px */


    color: #000000;
}

.time-btn1 {
    border: none;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */
    border-radius: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #7D7EC7;
    padding-left: 30px;
    padding-right: 30px;
    color: #FFFFFF;

    margin-top: 30px;
}

.time-btn3 {
    border: none;
    font-family: 'Poppins',  Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height */
    border-radius: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #7D7EC7;
    padding-left: 10px;
    padding-right: 10px;
    color: #FFFFFF;

    margin-top: 18px;
}

.time-title {

    font-family: 'Inter',  Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */


    color: #000000;
}

.order-details {
    font-family: 'Inter',  Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;

    color: #000000;
}

.submit-review {
    font-family: 'Inter',  Sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 50px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #7D7EC7;
}

.time-line-chat {
    display: flex;
    margin-top: 100px;
}

.time-btn2 {
    font-family: 'Poppins',  Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13.0359px;
    line-height: 20px;
    /* identical to box height */


    border: 1px solid #7D7EC7;
    color: #7D7EC7;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 15px;
}

.time-data-flex {
    display: flex;
}

.time-data3 {
    width: 230px;
    height: 180px;
    background-color: white;
}

.time-input {

    background: #FFFFFF;
    border-radius: 8px;
    outline: none;
    border: 1px solid whitesmoke;
    margin-left: 70px;
    width: 80%;
}

.send {
    background-color: #7D7EC7;
    border: none;
    border-radius: 50%;
}

.attach {
    border: none;
    background: transparent;
}

.time-side1 {
    flex-basis: 30%;
    height: 1421px;

}

.attachment {
    font-family: 'Inter',  Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    margin-top: 10px;

    color: #000000;
}

.time-side {
    height: auto;
    border-radius: 5px;
    background: #F0EEFE;
    margin-left: 20px;
    text-align: left;
    padding: 20px;
}








.onel,
#box {
    color: rgb(91, 92, 184);
    font-size: 18px;

}




.iaccept {
    background-color: rgb(91, 92, 184);
    color: #fff;
    padding: 0px 80px 0px 80px;
    border-radius: 10px;
    border: none;
}

@media (min-width: 320px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 18px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .onel,
    #box {
        font-size: 10px;
    }

    .iaccept {
        padding: 1px 10px 1px 10px;
        font-size: 8px;
        border-radius: 5px;
    }

}

@media (min-width: 375px) {
    .chng {
        font-size: 9px;
    }

    .heads {
        font-size: 12.5px;
        margin-bottom: 10px;
    }

    .cookie {
        font-size: 20px;
        padding: 40px 0px 0px 0px;
        margin: 0;
        letter-spacing: 1px;
    }

    .innertext {
        font-size: 8px;
    }

    .outline {
        margin: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .onel,
    #box {
        font-size: 10px;
    }

    .iaccept {
        padding: 2px 10px 2px 10px;
        font-size: 10px;
        border-radius: 5px;
    }


}

@media (min-width: 425px) {
    .cookie {
        font-size: 25px;
        padding: 50px 0px 0px 0px;
        margin: 0;
        letter-spacing: 2px;
    }

    .outline {
        margin: 38px;
    }

    hr {
        border: none;
        border-top: 1px solid black;
        width: 80%;
    }

    .innertext {
        font-size: 12px;
    }

    .chng {
        font-size: 12px;
    }

    .heads {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .onel,
    #box {
        font-size: 12px;
    }

    .iaccept {
        padding: 2px 15px 2px 15px;
        font-size: 10px;
        border-radius: 5px;
    }

}

@media (min-width: 768px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 45px;
    }

    .pr .outline {
        margin: 70px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 16px;
    }

    .chng {
        font-weight: 700;
        font-size: 16px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 18px;
    }

    .onel,
    #box {
        font-size: 15px;
    }

    .iaccept {
        padding: 2px 48px 2px 48px;
        font-size: 15px;
        border-radius: 5px;
    }

}

@media (min-width: 1024px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 100px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 19px;
    }

    .chng {
        font-weight: 700;
        font-size: 19px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 22px;
    }

    .onel,
    #box {
        font-size: 20px;
    }

    .iaccept {
        padding: 2px 80px 2px 80px;
        font-size: 20px;
        border-radius: 5px;
    }
}

@media (min-width: 1440px) {
    .cookie {
        letter-spacing: 2px;
        padding: 100px 100px 30px 100px;
        font-size: 48px;
    }

    .outline {
        margin: 120px;
    }

    hr {
        width: 70%;
    }

    .innertext {
        font-weight: 400;
        font-size: 20px;
    }

    .chng {
        font-weight: 700;
        font-size: 20px;
    }

    .heads {
        margin-bottom: 25px;
        font-size: 25px;
    }

    .onel,
    #box {
        font-size: 25px;
    }

    .iaccept {
        padding: 3px 100px 3px 100px;
        font-size: 25px;
        border-radius: 5px;
    }
}