.service-l-head {
    position: relative;
    top: 140px;


    color: #7D7EC7;

}
.button-view{
    background: #7D7EC7;
    border: none;
    color: white;
    font-size: 15px;
    font-family: 'Poppins',  sans-serif;
    font-style: normal;
    font-weight: 500;
    margin-top: 13px;

    border-radius: 5px;
    padding: 3px 20px 3px 20px;
}
.font3{
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;

background: tranparent;
border: 2px solid #7D7EC7;
border-radius: 5px;



margin-left: 10px;
padding: 3px 20px 3px 20px;
/* identical to box height */


color: #7D7EC7;
}

.font {
    font-family: 'Poppins',  sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
 
    /* identical to box height */
    
    
    color: #545454;
}
.cardlanding-rating{
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 500;
font-size: 15px;

/* identical to box height */


color: #7D7EC7;

}
.cardlanding-content{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    
    width: 95%}
.font2{
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: bold;
font-size: 15px;
line-height: 106.7%;
/* identical to box height, or 30px */


color:black;
}
.pagecontainer{
    width:85%;
 
    height: auto;
    margin: auto;
}

.service-landing {
    text-align: left;
    width: 100%;


   
    /* or 52px */



    /* color: #7D7EC7; */
}

.service-l-flex {
    display: flex;

justify-content: space-between;
height: auto;
margin-top: 190px;

}

.service-profile-detail {
    flex-basis: 40%;
    align-items: left;
position: relative;


    

}
.card-landing-line{
    transform: rotate(90deg);
position: absolute;
width: 40%;
z-index: 99;
left: 34%;
    top: 500px;
}
.margin{
    margin-top: 50px;
}
.seller-payment-sec {
    flex-basis: 40%;

    height: 515px;
 text-align: left;

    
   


    


}
.includes-content{
    
font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 400;
font-size: 17px;

/* identical to box height */


color: #545454;
}
.includes-head{
    
font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 800;
font-size: 17px;

/* identical to box height */


color: #161616;

}
.font-des-content{
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 169%;
/* or 47px */

letter-spacing: 0.02em;

color: #545454;

}
.time2{
    margin-top: 10px;
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 400;
font-size: 15.986px;
line-height:px;
/* identical to box height */


color: #545454;

}
.font-des{
    
font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 42px;
/* identical to box height */


color: #000000;

}
.includes{
   margin-top: 40px;
   position: relative;
   left: -6%;
}
.landing-btn1{
    
background: #7D7EC7;
border-radius: 3px;
border: none;
font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 600;
font-size: 15.986px;
padding-bottom: 3px;
width: 40%;
/* identical to box height */


color: #FFFFFF;
}
.landing-btn2{
    
    background: transparent;
    border-radius: 3px;
   
    font-family: 'Poppins',  sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15.986px;
    line-height: px;
margin-top: 15px;
    /* identical to box height */
    
    border: 1px solid  #7D7EC7;
    
    color:#7D7EC7 ;
    }


    .font-pay-head{
        
font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 700;
font-size: 15.9836px;
line-height: 131%;
/* identical to box height, or 37px */

text-align: center;

color: #2F2F2F;

    }
.card-flag{
    margin-top: 15px;
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: px;
/* identical to box height */


color: #545454;
}
.seller-payment-sec-content{
    background: #F0EEFE;
    padding: 30px;
    width: 80%;

    
}
.service-payment-detail {


    height: 300px;
}

.service-profile-pic {
    flex-basis: 50%;
    padding: 20px;

    height: 170px;
    background: linear-gradient(90deg, #E7E4FE 0%, rgba(231, 228, 254, 0) 103.31%);
    border-radius: 200px 0px 0px 200px;
}

.service-about-head {
    position: relative;
    font-family: 'Poppins',  sans-serif;
    font-style: normal;
    font-weight:bold;
    font-size: 15px;
    line-height: 131%;
    /* identical to box height, or 37px */
    
    text-align: left;
    
    color: #2F2F2F;
margin-top: 70px;
    
}

.service-coun-dis {
    margin-top: 50px;
    left: "";
    width: 100%;
    padding: 40px;
    margin-bottom: 100px;

    background: #F0EEFE;
}
.profile-flex{
       flex-basis: 70%;
        text-align: left;
}
.service-about {
margin-top: 30px;
    width: 80%;

}
.landing-pic{
    position: relative;
    top: -2.2px;
}
.landing-name{
    font-family: 'Poppins',  sans-serif;
font-style: normal;
font-weight: 600;
font-size: 18px;

/* or 47px */



color: #7D7EC7;
}
.seller-payment-sec2{
display: none;
}
.includes-mobile{
    display: none;
}
.hr-mobile{
    display: none;
    width: 95%;
    margin: auto;
}
@media (max-width:1344px) {
.pagecontainer{
    width: 95%;
}    

}


@media (max-width:990px) {
.service-l-flex{
    display: block;
    justify-content: left;

}   
.card-landing-line{
    display: none;
}
.hr-mobile{
    display: block;
}
.includes-mobile{
    display: block;
    margin-top: 50px;
}
.margin{
    margin-bottom: 5px;
}
.cardlanding-content{
    margin: auto;
}
.service-coun-dis{
    margin-bottom: 50px;
}

.service-profile-pic{
    height: auto;
}
.seller-payment-sec2{
    top: 0px;
display: block;
    width: 90%;
    margin: auto;
  
}
.seller-payment-sec{
    display: none;
}
.includes{
    margin-left: 40px;
}
.seller-payment-sec-content{
    float: none;
    top: 0px;
  
  
    margin: auto;

}
.service-about-head{
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
    
  
}

.font-des-content{
    text-align: center;

}
.font-des{
    text-align: center;
    margin-bottom: 0px;
}
.service-about{
    width: 100%;
    margin-top: 10px;
}

}
@media (max-width:500px) {
    .pagecontainer{
        width: 97%;
    }
    .profile-flex{
        flex-basis: 90%;
    }
    .seller-payment-sec{
        width: 100%;

    }
    .seller-payment-sec-content{
        width: 100%;
    }
    .font3{
        margin-top: 10px;
        width: 30%;padding: 3px 10px 3px 10px;
        font-size: 10px;
    }
    .button-view{
        width: 40%;padding: 3px 10px 3px 10px;
        font-size: 10px;
        
    }
 
}
@media (max-width:361px) {
.service-profile-pic{
    width: 100%;
}    

.pagecontainer{
    width:99%;
}
.button-view{
    width: 50%;padding: 3px 10px 3px 10px;
    font-size: 12px;
    
}
.font3{


    width: 30%;padding: 3px 10px 3px 10px;
    font-size: 12px;
    

}

.service-profile-detail{
    width: 100%;
}
.cardlanding-content{
    width: 99%;
}
.font{
    font-size: 13px;
}

}