.signup-flexs {
  display: flex;
  width: 1000px;
  margin: 100px auto;


}

.signLogin {
  width: 100%;
 
  z-index: 99;
}

.form-label {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17.8187px;
  line-height: 0px;
  /* identical to box height */


  color: #000000;

}

.sinup-pic-content {
  font-family: 'Gotham Book', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 134.5%;
  /* or 43px */

  text-align: center;
  letter-spacing: -0.015em;

  color: #FFFFFF;
}

.remind {

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15.8187px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: 0.02em;
  border: none;
  background: transparent;
  text-decoration: underline;
  color: #3F3B6C;
}

.wrong {
  float: left;
  margin-left: 20px;
  margin-top: 10px;
  border: none;
  background: transparent;
  font-weight: bold;
  color: white;
}

.wrong2 {
  float: right;
  margin-left: 20px;
  display: none;
  border: none;
  background: transparent;
  font-weight: bold;
  color: black;
}

/* .active{
  -webkit-animation: blur 2s forwards;
  -moz-animation: blur 2s forwards;
  -o-animation: blur 2s forwards;
  animation: blur 2s forwards;
  
}
.active2{
  -webkit-animation: blur-out 2s forwards;
  -moz-animation: blur-out 2s forwards;
  -o-animation: blur-out 2s forwards;
  animation: blur-out 2s forwards;
  
}


@keyframes blur {
0% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

100% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}
}

@-webkit-keyframes blur-out {
0% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
}

@-moz-keyframes blur-out {
0% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
}

@-o-keyframes blur-out {
0% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
}

@keyframes blur-out {
0% {
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
}

100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
}
} */

.hr4 {
  transform: rotate("90deg");
}

.login-class {
  color: white;
}

.signup-forms {
  text-align: left;
  align-items: left;
  position: relative;
  box-sizing: border-box;
  top: px;
  width: 530px;
  height: 530px;


  background-color: white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);

  padding: 20px;
}


.signup-pic {
  background-color: #7275C4;
  flex-basis: 520px;
  height: 530px;
  position: relative;


}

.form-width {
  width: 90%;
  margin: auto;
}

.sign-goggle {
  background-color: white;
  border: none;
  border: 0.809207px solid rgba(0, 0, 0, 0.25);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1841px;

  /* identical to box height */

  width: 100%;

  color: #000000;
  border-radius: 5.09207px;
  padding: 5px 10px 5px 10px;
}


.sign-button {
  width: 96%;
  background-color: #7275C4;
  border: none;
  
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1841px;
  color: white;

  /* identical to box height */


  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5.09207px;

  padding: 5px 10px 5px 10px;
}

.getLink {
  width: 100%;
  background-color: #7275C4;
  border: none;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16.1841px;
  color: white;

  /* identical to box height */
  margin: auto;
  margin-left: px;
  margin-top: 30px;
  border-radius: 5.09207px;
  padding: 5px 10px 5px 10px;
}

.signup-info {
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.266);
  border-radius: 0px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17.8187px;

  /* identical to box height */

  width: 96%;
  outline: none;
  
  position: relative;
  top: -5px;
  color: #7B7B7B;
}

.Signup-head2 {


  margin-bottom: 10px;

}

.Signup-heads3 {


 
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15.3683px;

  line-height: 49px;
  /* identical to box height */
  margin-top: 0px;


  color: #3F3B6C;


}



.Signup-heads {

  position: relative;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15.3683px;

  line-height: 49px;
  /* identical to box height */
  margin-top: 0px;
  margin-left: 27px;



  color: #3F3B6C;
}

.signup-btn,
.login-btn {

  background: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-style: normal;


  line-height: 22px;
  /* identical to box height */


  color: #000000;




  font-size: 20px;
  font-weight: 700;
}

.need {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13.8187px;
  line-height: 25px;
  /* identical to box height */

  letter-spacing: 0.02em;
  text-align: center;
  color: black;
}

.sign-hr {
  transform: rotate(90deg);
  position: absolute;
  width: 30px;
  top: 13px;
  left: 90px;
}

.sign-hr2 {
  transform: rotate(90deg);
  position: absolute;
  width: 30px;
  top: 120px;
  left: 95px;
}

.login-btn {
  position: relative;
  left: 5%;
}

.signup-btn {
  

  padding-right: 9px;
  cursor: pointer;

}

.signup-btn.active {

  padding-bottom: 5px;
  color: #7275C4;
  border-bottom:5px solid  #7275C4 ;

}

.loginlogin {
  width: 87%;
  margin: auto;
}

.login-forms {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  position: relative;
  top: 20px;


  height: 800px;
  border: 2px;
  display: none;
  background-color: white;

  text-align: left;

  padding: 20px;

}

.res-img {
  position: relative;
  top: px;
}

.signup-btn2 {
  background: transparent;

  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 17.8187px;
  line-height: 22px;
  /* identical to box height */


 
  border-bottom: 2px solid #3F3B6C;
  padding-bottom: 5px;
  margin-left: 27px;
  color: #7275C4;


}

@media (max-width:990px) {
  .signup-flexs {
    display: block;
    position: relative;
    margin: auto;
    width: 100%;

  }

  .login-forms {

    top: 300px;
  }

  .wrong2 {
    display: block;
  }

  .signup-forms {
    width: 60%;
    margin: auto;
    top: 80px;
  }

  .signup-pic {
    width: 100%;
    display: none;
  }

  

  .res-img {
    width: 50%;
    position: relative;
    top: 300px;
  }

}

@media (max-width:600px) {
  .signup-forms {
    width: 90%;
  }

  .loginlogin {
    width: 90%;
  }

  .sign-hr2 {
    left: 65px;
  }

  .form-width {
    width: 100%;
  }

  .Signup-heads {
    margin-left: 0px;
  }

  .login-forms {
    width: 100%;
  }

  .signup-btn2 {
    margin-left: 0px;
  }
}