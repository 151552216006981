.social-media2 {
    width: 22px;
    margin: 5px;
}

.about-team-pic {
    width: 80px;
}



.about-detail {

    width: 100%;
    height: 450px;

    position: relative;
    top: 100px;
    background-image: url("./images/aboutus.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;
    justify-content: center;

}

.about-text {
    font-family: 'Gotham Book ', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 135.5px;
    line-height: 237px;
    /* identical to box height */
    color: white;
    position: relative;
    top: 110px;
}

.incube-about {
    margin-top: 50px;
}


.incube-head {
    font-family: 'Gotham Book', sans-serif;
    font-style: normal;
    font-weight: 700;

    line-height: 40px;
    letter-spacing: 0.02em;

    color: #000000;
}




.display-mobile{
    display: none;
}
.Pedal-logo {
    position: relative;
    top: 800px;
    left: 10%;
    padding: 10px;
    width: 50px;
    background-color: #D9D9D9;

}

.incube-logo {
    /* position: relative;
    top: 800px; */


    padding: 10px;
    width: 50px;
    background-color: #D9D9D9;

}

.about-content {
    display: flex;

    justify-content: space-between;
}

.about-pic {
    flex-basis: 20%;
    height: 500px;
    position: relative;
    top: 70PX;
    left: 8%;

}

.about-text2 {
    flex-basis: 60%;
    height: auto;
    margin-right: 50px;

    margin-top: 140px;
    text-align: justify;

}

.about-text3 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 158.02%;
    /* or 28px */

    text-align: justify;
    letter-spacing: 0.02em;

    color: #000000;

    /* position: relative;]{
    top: 160px; */
    box-sizing: border-box;

}

.about-head {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    letter-spacing: 0.02em;
    color: #000000;
}

.row1 {
    display: flex;
    text-align: center;
    justify-content: center;
}

.our-team {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.02em;
    margin-top: 70px;

}

.incube-head2 {
    font-family: 'Gotham Book ', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36.3276px;
    line-height: 40px;
    letter-spacing: 0.02em;

    color: #000000;
}

.incube2 {
    position: relative;

    top: 350px;
}



.mteam {

    width: 200px;
    margin: 30px auto;

    border-radius: 20px;

    padding: 10px;
    height: 200px;
    background-color: rgba(234, 233, 244, 0.73);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

}



.incube-pic3 {
    position: relative;
    left: -10%;
}

.incube-pic4 {
    position: relative;
    left: 10%;
}

.about-team-name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 158.02%;
    /* or 28px */

    text-align: center;
    letter-spacing: 0.02em;

    color: #000000;

}

.container7 {
    border-top: 2px solid rgba(0, 0, 0, 0.221);
    width: 100%;
    height: 600px;
    position: relative;
    top: 500px;
    bottom: 0;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
    background: #11112E;
}


@media (max-width:990px) {
    .about-text {
        position: relative;
        top: 100px;
    }


}

@media (max-width:600px) {
    .about-content {
        display: block;
        margin-top: 70px;
    }
    .incube-pc{
        display: none;
    }
    .display-mobile{
        display: block;
      position: relative;
      top: -50px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .about-detail {
        height: 500px;

    }

    .about-text {
        font-size: 80px;
        top: 150px;
    }

    .about-pic {
        left: 0px;
    }

    .about-text2 {
        width: 95%;
        margin: auto;
    }

    .row1 {
        display: block;
    }

    .about-head {
        margin: auto;
        width: 60%;
        font-size: 15px;
    }
}

@media (max-width:400px) {
    .about-head {
        font-size: 17px;
        margin-top: 20px;
    }

    .about-text {
        font-size: 50px;
    }

}

@media (max-width:365px) {
    .about-text {
        font-size: 50px;
    }

}