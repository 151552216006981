* {
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  font-style: normal;
}

.carousel {
  background:white;
  box-shadow: 0px 72.5294px 108.794px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 500px;


}

.flickity-page-dots{
  position:absolute;
  bottom: -83px;
  display: none;
}
.INR2 {
  position: relative;
  top: -50PX;
  margin: 5PX;
}



.INR7 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: px;
  line-height: 96.7%;

  margin: 3px;
  letter-spacing: -0.03em;

  color: #7D7EC7;
}



.carousel9 {
  padding-top: 2px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  top: -5px;
  height: 40px;

}

.popular-seller-text {
  position: absolute;
  top: 0px;

}

.popular-seller-heading {
  color: #303035;

  position: relative;
  left: 10%;
  top: 30PX;
  font-family: 'Baloo Thambi 2',  sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 169.9%;
  /* identical to box height, or 54px */

  letter-spacing: 0.02em;


  text-align: left;

}

.popular-seller-heading2 {
  color: #636368;

  position: relative;
  left: 10%;
  top: 30PX;

}

.Slider-heading {

  background-color: white;
  height: 170px;
 margin-top: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;


  text-align: left;
}

.carousel-cell {

  
  width: 285px;
  height: 460px;
  margin: auto;


  background-color: white;
  box-shadow: 0px 0px 16.6488px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  position: relative;
  left: -px;
  counter-increment: carousel-cell;



}
.service-card-main{
  width: 100%;
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: auto;
  background-color:#7D7EC7;
}
.carousel-cell-top {

   
  width: 285px;
  height: 470px;
  /* margin:20px auto; */
  margin-left: 10px;
 margin-top: 10px;


  background-color: white;
  box-shadow: 0px 0px 16.6488px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  position: relative;
  left: -px;
  counter-increment: carousel-cell;



}

.flag-slider {

  width: 35PX;
  background: #F0EFF7;
  height: 35px;
  margin-top: 13px;

  /* margin-right: 20px;
margin-left:  50px;
margin-bottom:  40px; */
  border-radius: 50%;
 margin-right: 40px;
  position: relative;
  left: -px;

  counter-increment: carousel-cell;
}

.flag {
  background-color: white;
  padding: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  position: relative;

  display: none;
  height: auto;
  font-size: 13px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.flag-pic {

  margin: auto;
}

.flag-pic2 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

}

.slider-profilepic {
  background-color: white;
  width: 40px;
  height: 20px;

  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 10px;
}

/* .carousel-cell.is-selected {
    background: #ED2;
  } */

/* cell number */
/* .carousel-cell:before {
    display: block;
    text-align: center;
    content: counter(carousel-cell);
    line-height: 200px;
    font-size: 80px;
    color: white;
    
  }  */
/* .cells{
    width: 390px;
    height: 700px;
  
    
  } */

/* /* .slider2{
    position: relative;
    top: 140px;
    
    width:100%;
    height: 250px;
  
  
  } */
.slider-overflow {
  width: 90%;
  margin: 0 auto;
}

.flickity-prev-next-button {
  top: 10px;
  -webkit-transform: none;
  transform: none;
  width: 25px;

}

.flickity-prev-next-button.previous {

  float: left;

  background: none;
  opacity: 30%;
  top: 20%;

}

.flickity-prev-next-button.next {

  opacity: 30%;
  top: 20%;
 
 
  
  
}

.flickity-prev-next-button.next:hover {
  opacity: 90%;
}



.flickity-prev-next-button.previous:hover {
  opacity: 90%;
}

.popular-seller-view-more {
  position: relative;
  top: 20px;
  float: right;
  margin-right: 170px;
  font-family: 'Baloo Thambi 2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  line-height: 46px;

  color: #636368;

}

.slider2 {
 

}


@media (max-width:1280px) {

  
  .carousel-cell18 {

    margin-left: 16px;
    margin-right: 16px;
  }


}



@media (max-width:990px) {

  

  

  .slider2 {
    margin-top: 500px;

  }
  .carousel-cell-top{
    margin: 30px auto;
  }


}

/* @media (max-width:700px) {

  .carousel-cell{
    width:100%
  }
} */







@media (max-width:600px) {


  .slider9 {
    position: relative;
    top: -120px;
  }
  .flickity-prev-next-button.next{
    display: none;
  }
  .flickity-prev-next-button.previous{
    display: none;
  }
.Slider-heading {
  text-align: center;
  
}
  .slider2 {
    margin-top: 1100px;

  }


 
  .popular-seller-heading {
    text-align: center;
    top: -5px;
    left: 0px;
  }

  .popular-seller-heading2 {
    top: -20px;
  
    float: none;

  }

  .popular-seller-view-more {
    margin: auto;
    position: relative;
    top: 20px;
    float: none;
    text-align: center;


  }

}



@media (max-width:500px) {


 

  .slider2 {
    margin: 800px auto;
  }

  .popular-seller-heading {
    font-size: 30px;
    top: 10px;
  }

  .popular-seller-heading2 {
    font-size: 12px;
    line-height: 1px;
    top: 5px;
    left: 0px;
  }

  .popular-seller-view-more {
    font-size: 12px;
    top: 20px;
  }


}

@media (max-width:396px) {

  .carousel {
    width: 100%;

  }



  .popular-seller-heading {
    font-size: 30px;
    top: 10px;
  }

  .popular-seller-heading2 {
    font-size: 12px;
    line-height: 1px;
    top: 5px;
    
  }

  .popular-seller-view-more {
    font-size: 20px;
    top:10px;
    float: none;
    left: 0px;
  }

  .slider2 {
    margin-top: px;
  }

}

@media (max-width:361px) {
  .popular-seller-heading {
    font-size: 25px;
    top: 10px;
  }

  .popular-seller-heading2 {
    font-size: 10px;
    line-height: 1px;
    top: 5px;
  }


}

@media (max-width:320px) {
  .carousel {
    top: px;
  }

  
  .slider2 {
    margin-top: 730px;
    left: 2%;
  }

  .popular-seller-heading {
    font-size: 20px;
    top: 10px;
  }

  .popular-seller-heading2 {
    font-size: 10px;
    line-height: 1px;
    top: 5px;
  }

  .popular-seller-view-more {
    font-size: 12px;
    top: 20px;
    left: -3%;
  }

}
